import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { SvgIcon } from '@mui/material';
import HomeSmileIcon from 'src/shared/icons/home-smile';
import Lock01Icon from 'src/shared/icons/lock-01';
import ReceiptCheckIcon from 'src/shared/icons/receipt-check';
import ShoppingBag03Icon from 'src/shared/icons/shopping-bag-03';
import Upload04Icon from 'src/shared/icons/upload-04';
import Users03Icon from 'src/shared/icons/users-03';
import { paths } from 'src/routes/paths';
import LineChartUp04 from '../../../icons/line-chart-up-04';
import { Permission } from 'src/models/permission';


export interface NavItem {
    disabled?: boolean;
    external?: boolean;
    icon?: ReactNode;
    items?: NavItem[];
    label?: ReactNode;
    path?: string;
    title: string;
    permissions?: Permission[];
}

export interface NavSection {
    items: NavItem[];
    subheader?: string;
    permissions?: Permission[]
}

export const useSideNavSections = () => {

    return useMemo(
        () => {
            return [
                {
                    items: [
                        {
                            title: "Dashboard",
                            path: paths.dashboard,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <HomeSmileIcon />
                                </SvgIcon>
                            )
                        }
                    ]
                },
                {
                    items: [
                        {
                            title: "Artifacts",
                            permissions: [Permission.ArtifactsRead],
                            path: "",
                            icon: (
                                <SvgIcon fontSize="small">
                                    <ShoppingBag03Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: "Search",
                                    path: paths.artifacts.list,
                                    permissions: [Permission.ArtifactsRead],
                                },
                                {
                                    title: "Create",
                                    path: paths.artifacts.create,
                                    permissions: [Permission.ArtifactsCreate],
                                },
                                {
                                    title: "Transfer",
                                    path: paths.artifacts.transfer,
                                    permissions: [Permission.ArtifactsTransfer],
                                }
                            ]
                        },
                        {
                            title: "Investors",
                            permissions: [Permission.DevelopmentFeatures],
                            path: "",
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Users03Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: "Search",
                                    path: ""
                                },
                                {
                                    title: "Create",
                                    path: ""
                                },
                            ]
                        },
                        {
                            title: "Logs",
                            permissions: [Permission.DevelopmentFeatures],
                            path: "",
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Upload04Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: "Sheet Entry",
                                    path: ""
                                },
                                {
                                    title: "Import",
                                    path: ""
                                },
                            ]
                        },
                        {
                            title: "Datasets",
                            permissions: [Permission.DevelopmentFeatures],
                            path: "",
                            icon: (
                                <SvgIcon fontSize="small">
                                    <ReceiptCheckIcon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: "Surfer",
                                    path: ""
                                },
                                {
                                    title: "Thorn",
                                    path: ""
                                },
                                {
                                    title: "AutoCAD",
                                    path: ""
                                },
                                {
                                    title: "Illustration",
                                    path: ""
                                }
                            ]
                        },
                        {
                            title: "Reports",
                            permissions: [Permission.ArtifactsRead],
                            path: "",
                            icon: (
                                <SvgIcon fontSize="small">
                                    <LineChartUp04 />
                                </SvgIcon>
                            ),       
                             items: [
                                {
                                    title: "Coin Detail",
                                    path: paths.reports.coinDetails,
                                    permissions: [Permission.ArtifactsRead],
                                }
                            ]

                        },

                    ]
                },
                {
                    subheader: "Management",
                    permissions: [Permission.DataManagementRead, Permission.DivisionManagementRead],
                    items: [
                        {
                            title: "Data Management",
                            permissions: [Permission.DataManagementRead],
                            path: "",
                            icon: (
                                <SvgIcon fontSize="small">
                                    <ReceiptCheckIcon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: "Address Types",
                                    path: paths.admin.addressTypes.list
                                },
                                {
                                    title: "Artifact Classes",
                                    path: paths.admin.artifactClasses.list
                                },
                                {
                                    title: "Artifact Descriptions",
                                    path: paths.admin.artifactDescriptions.list
                                },
                                {
                                    title: "Artifact Groups",
                                    path: paths.admin.artifactGroups.list
                                },
                                {
                                    title: "Artifact Locations",
                                    path: paths.admin.artifactLocations.list
                                },
                                {
                                    title: "Artifact Statuses",
                                    path: paths.admin.artifactStatuses.list
                                },
                                {
                                    title: "Artifact Types",
                                    path: paths.admin.artifactTypes.list
                                },
                                {
                                    title: "Assayers",
                                    path: paths.admin.assayers.list
                                },
                                {
                                    title: "Associate Types",
                                    path: paths.admin.associateTypes.list
                                },
                                {
                                    title: "Bins",
                                    path: paths.admin.bins.list
                                },
                                {
                                    title: "Buildings",
                                    path: paths.admin.buildings.list
                                },
                                {
                                    title: "Blue Hole Contract Types",
                                    path: paths.admin.blueHoleContractTypes.list
                                },
                                {
                                    title: "Chemical Stabilization Methods",
                                    path: paths.admin.chemicalStabilizations.list
                                },
                                {
                                    title: "Chloride Release Tests",
                                    path: paths.admin.chlorideReleaseTests.list
                                },
                                {
                                    title: "Chloride Removal Processes",
                                    path: paths.admin.chlorideRemovals.list
                                },
                                {
                                    title: "Coin Date Remarks",
                                    path: paths.admin.coinDateRemarks.list
                                },
                                {
                                    title: "Concretion Removal Methods",
                                    path: paths.admin.concretionRemovalMethods.list
                                },
                                {
                                    title: "Conservators",
                                    path: paths.admin.conservators.list
                                },
                                {
                                    title: "Conservation Results",
                                    path: paths.admin.conservationResults.list
                                },
                                {
                                    title: "Contract Types",
                                    path: paths.admin.contractTypes.list
                                },
                                {
                                    title: "Curator Dispositions",
                                    path: paths.admin.curatorDispositions.list
                                },
                                {
                                    title: "Denominations",
                                    path: paths.admin.denominations.list
                                },
                                {
                                    title: "Dispositions",
                                    path: paths.admin.dispositions.list
                                },
                                {
                                    title: "Divison Types",
                                    path: paths.admin.divisionTypes.list
                                },
                                {
                                    title: "Email Address Types",
                                    path: paths.admin.emailAddressTypes.list
                                },
                                {
                                    title: "Galleon Activity Areas",
                                    path: paths.admin.galleonActivityAreas.list
                                },
                                {
                                    title: "Galleon Sections",
                                    path: paths.admin.galleonSections.list
                                },
                                {
                                    title: "Grades",
                                    path: paths.admin.grades.list
                                },
                                {
                                    title: "Investor Levels",
                                    path: paths.admin.investorLevels.list
                                },
                                {
                                    title: "Investor Relations Contacts",
                                    path: paths.admin.investorRelationsContacts.list
                                },
                                {
                                    title: "Investor Types",
                                    path: paths.admin.investorTypes.list
                                },
                                {
                                    title: "Lead Sources",
                                    path: paths.admin.leadSources.list
                                },
                                {
                                    title: "Mints",
                                    path: paths.admin.mints.list
                                },
                                {
                                    title: "Operations",
                                    path: paths.admin.operations.list
                                },
                                {
                                    title: "Owners",
                                    path: paths.admin.owners.list
                                },
                                {
                                    title: "Payment Method Types",
                                    path: paths.admin.paymentMethodTypes.list
                                },
                                {
                                    title: "Pearl Grades",
                                    path: paths.admin.pearlGrades.list
                                },
                                {
                                    title: "Phone Number Types",
                                    path: paths.admin.phoneNumberTypes.list
                                },
                                {
                                    title: "Protocols",
                                    path: paths.admin.protocols.list
                                },
                                {
                                    title: "Reigns",
                                    path: paths.admin.reigns.list
                                },
                                {
                                    title: "Safes",
                                    path: paths.admin.safes.list
                                },
                                {
                                    title: "Shelves",
                                    path: paths.admin.shelfs.list
                                },
                                {
                                    title: "Trade Types",
                                    path: paths.admin.tradeTypes.list
                                },
                                {
                                    title: "Vessels",
                                    path: paths.admin.vessels.list
                                },
                                {
                                    title: "Wreck Sites",
                                    path: paths.admin.wreckSites.list
                                },
                            ]
                        },
                        {
                            title: "Division Management",
                            permissions: [Permission.DivisionManagementRead],
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Users03Icon />
                                </SvgIcon>
                            )
                        }
                    ]
                },
                {
                    subheader: "Administration",
                    items: [
                        {
                            title: "Security",
                            path: "",
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Lock01Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: "Users",
                                    path: paths.admin.users.list
                                },
                                {
                                    title: "IP Addresses",
                                    path: paths.admin.ipAddresses.list

                                }
                            ]
                        }]
                },
            ];
        },
        []
    );
};
