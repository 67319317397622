export enum ApiQuery {
	// Principal
	GetPrincipal,

	//Users
	GetUsers,
	GetUser,

	//IP Addreses
	GetIpAddresses,

	//Operations
	GetOperations,

	//Vessels
	GetVessels,

	//WreckSites
	GetWreckSites,

	//ArtifactLocations
	GetArtifactLocations,

	//Assayers
	GetAssayers,

	//CuratorDispositions
	GetCuratorDispositions,

	//ArtifactStatuses
	GetArtifactStatuses,

	//Mints
	GetMints,

	//Protocols
	GetProtocols,

	//Reigns
	GetReigns,

	//Denominations
	GetDenominations,

	//ArtifactTypes
	GetArtifactTypes,

	//Owners
	GetOwners,

	//Conservators
	GetConservators,

	//Grades
	GetGrades,

	//Artifact Classes
	GetArtifactClasses,

	//Dispositions
	GetDispositions,

	//Pearl Grades
	GetPearlGrades,

	//Chemical Stabilizations
	GetChemicalStabilizations,

	//Artifact Groups
	GetArtifactGroups,

	//Chloride Removals
	GetChlorideRemovals,

	//Division Types
	GetDivisionTypes,

	//Contract Types
	GetContractTypes,

	//Investor Types
	GetInvestorTypes,

	//Investor Relations Contacts
	GetInvestorRelationsContacts,

	//Payment Method Types
	GetPaymentMethodTypes,

	//Phone Number Types
	GetPhoneNumberTypes,

	//Associate Types
	GetAssociateTypes,

	//Investor Levels
	GetInvestorLevels,

	//Conservation Results
	GetConservationResults,

	//Email Address Types
	GetEmailAddressTypes,

	//Address Types
	GetAddressTypes,

	//Chloride Release Tests
	GetChlorideReleaseTests,

	//Concretion Removal Methods
	GetConcretionRemovalMethods,

	//Lead Sources
	GetLeadSources,

	//Trade Types
	GetTradeTypes,

	//Coin Date Remarks
	GetCoinDateRemarks,

	//Blue Hole Contract Types
	GetBlueHoleContractTypes,

	//Artifact Descriptions
	GetArtifactDescriptions,
	GetArtifactDescription,

	//Galleon Sections
	GetGalleonSections,
	GetGalleonSection,

	//Galleon Activity Areas
	GetGalleonActivityAreas,
	GetGalleonActivityArea,

	//Artifacts
	GetArtifacts,
	GetArtifact,

	//Artifact Files
	GetArtifactFile,
	GetArtifactFiles,
	
	//Buildings
	GetBuilding,
	GetBuildings,

	//Safes
	GetSafe,
	GetSafes,

	//Shelfs
	GetShelfs,
	GetShelf,

	//Bins
	GetBin,
	GetBins,

	//Artifact Transfers
	GetArtifactTransfers,

	//Artifact Certificates
	GetArtifactCertificates,
	GetArtifactCertificate,

	//Reports
	GetCoinDetails
}