
import { FC, useEffect, useRef, useState } from 'react';
import { useMount } from 'src/shared/hooks/use-mount';
import { Api } from 'src/api/api';
import { ApiQuery } from 'src/api/api-query';
import UIMessage from 'src/shared/components/ui/ui-message';
import { paths } from 'src/routes/paths';
import { useAuth } from 'src/shared/hooks/use-auth';
import { useNavigate } from 'react-router';
import { Permission } from 'src/models/permission';
import { artifactTagSearchModes } from 'src/models/artifact';

import { CoinSearchPanel, CoinSearchPanelDatasources, CoinSearchPanelFields } from './components/coin-search-panel';
import { Box, Stack, Typography } from '@mui/material';
import { GetWreckSitesQuery } from 'src/api/queries/wreck-site/get-wreck-sites';
import { GetVesselsQuery } from 'src/api/queries/vessel/get-vessels';
import { SearchResultsContentLayout } from 'src/shared/layouts/content/search-results-content-layout';
import { getLookupFormSelectItems, LookupFormSelectItemLabel } from 'src/shared/utils/get-lookup-select-items';
import { VesselRow } from 'src/models/vessel';
import { WreckSiteRow } from 'src/models/wreck-site';
import { GetLookupRowsQuery, GetLookupRowsQueryResult } from 'src/api/queries/lookup/get-lookup-rows';

import {
    PDFViewer,
    Page,
    Document,
    StyleSheet,
    Text,
    View,
} from "@react-pdf/renderer";
import { Table, TR, TH, TD } from '@ag-media/react-pdf-table';
import { CoinDetailsRow } from 'src/models/coin-details';
import { GetCoinDetailsQuery, GetCoinDetailsQueryResult } from 'src/api/queries/report/get-coin-details';
import { UIProgress } from 'src/shared/components/ui/ui-progress';
import { ReportBanner } from './components/report-banner';


const CoinDetailsReport: FC<{ data: CoinDetailsRow[], totalRecords: number, totalPoints: number }> = ({ data,totalRecords,totalPoints }) => {

    const styles = StyleSheet.create({
        page: {
            fontSize: 10,
            fontWeight: "normal",
            color: '#000',
            fontFamily: "Times-Roman",
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
            display:'flex'
        },
        heading: {
            fontSize: 18,
            fontFamily: "Times-Bold",
            textAlign: 'center',
        },
        subheading: {
            fontSize: 14,
            fontFamily: "Times-Bold",
            textAlign: 'center',
            marginBottom:10
        },
        tableHeading:{
            fontSize: 10,
            fontFamily: "Times-Bold", 
        },
        totals: {
            fontSize: 10,
            fontFamily: "Times-Bold", 
            width:140,
            marginLeft: 'auto',
            marginRight: 0
        },
        pageNumber: {
            position: 'absolute',
            fontSize: 8,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: 'right',
            marginRight:35,
            color: 'grey',
        },
        pageFooter: {
            position: 'absolute',
            fontSize: 8,
            bottom: 30,
            left: 0,
            right: 0,
            marginLeft:35,
            textAlign: 'left',
            color: 'grey',
        },
    });
    return (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
            <Document>

                <Page
                    size="LETTER"
                    orientation="landscape"
                    style={styles.page}
                >
           
                        <Text style={styles.heading}>Mel Fisher's Motivation, Inc.</Text>
                        <Text style={styles.subheading}>Coin Detail Report</Text>
                        <Table tdStyle={{height:40, border:'none', padding:2 }}>
                            <TH fixed style={styles.tableHeading}>
                                <TD weighting={0.0001}></TD>
                                <TD>Tag #</TD>
                                <TD>Description</TD>
                                <TD weighting={0.025} style={{ justifyContent: 'center' }}>O</TD>
                                <TD weighting={0.025} style={{ justifyContent: 'center' }}>R</TD>
                                <TD weighting={0.05}>Year</TD>
                                <TD weighting={0.05}>Weight</TD>
                                <TD>Coin Num</TD>
                                <TD>Mint</TD>
                                <TD>Reign</TD>
                                <TD>Denom</TD>
                                <TD>Assayer</TD>
                                <TD>Coin Date</TD>
                                <TD weighting={0.05}>Grade</TD>
                                <TD style={{ justifyContent: 'center' }}>Security #</TD>
                                <TD style={{ justifyContent: 'flex-end' }}>Points</TD>
                            </TH>
                            {data.map((artifact, index) => (
                                <TR
                                    key={index}
                                    style={{
                                        backgroundColor: index % 2 === 0 ? '#ddd' : '#fff',
                                    }}
                                >
                                     <TD weighting={0.0001}></TD>
                                    <TD>{artifact.tag}</TD>
                                    <TD>{artifact.artifactDescriptionName}</TD>
                                    <TD weighting={0.025} style={{ justifyContent: 'center' }}>{artifact.hasImageObverse ? "Yes" : "No"}</TD>
                                    <TD weighting={0.025} style={{ justifyContent: 'center' }}>{artifact.hasImageReverse ? "Yes" : "No"}</TD>
                                    <TD weighting={0.05}>{artifact.year}</TD>
                                    <TD weighting={0.05}>{artifact.postWeightGm}</TD>
                                    <TD>{artifact.coinNumber}</TD>
                                    <TD>{artifact.mintName}</TD>
                                    <TD>{artifact.reignName}</TD>
                                    <TD>{artifact.denominationName}</TD>
                                    <TD>{artifact.assayerName}</TD>
                                    <TD>{artifact.coinDate}</TD>
                                    <TD weighting={0.05}>{artifact.gradeName}</TD>
                                    <TD style={{ justifyContent: 'center' }}>{artifact.securitySticker}</TD>
                                    <TD style={{ justifyContent: 'flex-end' }}>{artifact.points}</TD>
                                </TR>
                            ))}
                        </Table>
              
                        <Text style={styles.pageFooter}
                        render={({ pageNumber, totalPages }) => (
                            `Printed on ${new Date().toLocaleString()}`
                        )} 
                        fixed />

                    <Text style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => (
                            `Page ${pageNumber} of ${totalPages}`
                        )} 
                        fixed />


<View style={styles.totals}>
    <Table style={{width: "100%"}} tdStyle={{height:25, border:'none', padding:2 }}>
        <TR>
            <TD>Total Points:</TD>
            <TD style={{ justifyContent: 'flex-end' }}>{totalPoints}</TD>
        </TR>
        <TR>
            <TD>Total Coins:</TD>
            <TD style={{ justifyContent: 'flex-end' }}>{totalRecords}</TD>
        </TR>
    </Table>
</View>
       
                </Page>
            </Document>
        </PDFViewer>
    );

}


  
const CoinDetailsReportPage: FC = () => {
    const auth = useAuth();
    const navigate = useNavigate();


    const [artifacts, setArtifacts] = useState<CoinDetailsRow[]>([]);
    const [totalArtifacts, setTotalArtifacts] = useState<number>(0);
    const [totalPoints, setTotalPoints] = useState<number>(0);

    const [coinArtifactDescriptionIds] = useState<number[]>([191,294,639]);
    const [loading, setLoading] = useState<boolean>(true);
    const [errors, setErrors] = useState<string[]>([]);
    const rootRef = useRef<HTMLDivElement | null>(null);
    const [searchPanelOpen, setSearchPanelOpen] = useState<boolean>(true);
    const [CoinSearchPanelDatasources, setCoinSearchPanelDatasources] = useState<CoinSearchPanelDatasources>();

    const handleSearchPanelSubmit = async (fields: CoinSearchPanelFields) => {
        setArtifacts([]);
        setTotalArtifacts(0);
        setTotalPoints(0);
        setLoading(true);

        if(fields.artifactDescriptionIds.length === 0 || fields.artifactDescriptionIds.includes(-1)) {
            fields.artifactDescriptionIds = coinArtifactDescriptionIds;
        }

        const result = await Api.executeQuery<GetCoinDetailsQuery, GetCoinDetailsQueryResult>(
            ApiQuery.GetCoinDetails, {
            ...fields
        });
        setArtifacts(result.rows);
        setTotalArtifacts(result.rows.length);
        setTotalPoints(result.rows.reduce((n, {points}) => n + (+(points ?? 0)), 0));

        setLoading(false);
    };

    const handleSearchPanelReset = async (fields: CoinSearchPanelFields) => {
        setLoading(true);

        setArtifacts([]);
        setTotalArtifacts(0);
        setTotalPoints(0);

        setLoading(false);
    };


    useMount(() => {

        if (!auth.authState.principal?.hasPermission(Permission.ArtifactsRead)) {
            navigate(paths.error403);
            return;
        }

        const loadData = async () => {

            try {

                const artifactDescriptions = (await Api.executeQuery<GetLookupRowsQuery, GetLookupRowsQueryResult>(ApiQuery.GetArtifactDescriptions, {}))
                    .rows.filter(f => coinArtifactDescriptionIds.includes(f.id));

                const operations = await getLookupFormSelectItems(ApiQuery.GetOperations, LookupFormSelectItemLabel.All);
                const wreckSites = await Api.executeRowsQuery<GetWreckSitesQuery, WreckSiteRow>(ApiQuery.GetWreckSites, {});
                const vessels = await Api.executeRowsQuery<GetVesselsQuery, VesselRow>(ApiQuery.GetVessels, {});

                setCoinSearchPanelDatasources({
                    artifactDescriptions,
                    operations,
                    wreckSites,
                    vessels,
                    artifactTagSearchModes
                });

                setLoading(false);
            } catch (err) {
                setErrors(err.response.data.errors || [err.response.statusText])
                setLoading(false);
            }
        }

        loadData();
    });

    return (
        <>
            <Box
                component="main"
                sx={{
                    display: 'flex',
                    flex: '1 1 auto',
                    overflow: 'hidden',
                    position: 'relative',

                }}
            >
                <Box
                    ref={rootRef}
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        left: 0,
                        bottom: 0
                    }}
                >

                    <CoinSearchPanel
                        datasources={CoinSearchPanelDatasources}
                        container={rootRef.current}
                        open={searchPanelOpen}
                        onOpenChanged={setSearchPanelOpen}
                        onSubmit={handleSearchPanelSubmit}
                        onReset={handleSearchPanelReset}
                    />

                    <SearchResultsContentLayout open={searchPanelOpen}>
                        <Stack spacing={4}>
                            <Stack
                                alignItems="flex-start"
                                direction="row"
                                justifyContent="space-between"
                                spacing={3}
                            >
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={2}
                                >

                                    <Typography variant="h4">
                                        Coin Detail Report
                                    </Typography>
                                </Stack>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >


                                </Stack>

                            </Stack>

                            <UIMessage
                                type="error"
                                messages={errors}
                                onClose={() => setErrors([])} />

                                     

                            <Stack>
                                {loading ? ( <UIProgress /> ) :  artifacts.length > 0 ?  (
                                <CoinDetailsReport data={artifacts} totalPoints={totalPoints} totalRecords={totalArtifacts} />
                                ) : (
                                    <ReportBanner/>
                                )}
                        


                            </Stack>

                        </Stack>

                    </SearchResultsContentLayout>


                </Box>

            </Box>

        </>



    );
};

export default CoinDetailsReportPage;

