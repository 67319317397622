export const paths = {
	
	login: '/login',
	password: {
		forgot: '/password/forgot',
		reset: '/password/reset/:token'
	},
	register: '/register/:token',
	dashboard: '/',
	artifacts: {
		list: '/artifacts',
		view: '/artifacts/:id',
		create: '/artifacts/create',
		update: '/artifacts/:id/edit',
		images: '/artifacts/:id/images',
		imagesUpdate: '/artifacts/:id/images/edit',
		transfer: '/artifacts/transfer'
	},
	reports: {
		coinDetails: '/reports/coin-details',
	},
	admin: {
		users: {
			list: '/admin/users',
			create: '/admin/users/create',
			view: '/admin/users/:userId',
			update: '/admin/users/:userId/edit'
		},
		ipAddresses: {
			list: '/admin/ip-addresses',
			create: '/admin/ip-addresses/create',
			update: '/admin/ip-addresses/:ipAddressId/edit'
		},
		operations: {
			list: '/management/operations',
			create: '/management/operations/create',
			update: '/management/operations/:id/edit'
		},
		wreckSites: {
			list: '/management/wreck-sites',
			create: '/management/wreck-sites/create',
			update: '/management/wreck-sites/:id/edit'
		},
		vessels: {
			list: '/management/vessels',
			create: '/management/vessels/create',
			update: '/management/vessels/:id/edit'
		},
		artifactLocations: {
			list: '/management/artifact-locations',
			create: '/management/artifact-locations/create',
			update: '/management/artifact-locations/:id/edit'
		},
		artifactStatuses: {
			list: '/management/artifact-statuses',
			create: '/management/artifact-statuses/create',
			update: '/management/artifact-statuses/:id/edit'
		},
		assayers: {
			list: '/management/assayers',
			create: '/management/assayers/create',
			update: '/management/assayers/:id/edit'
		},
		curatorDispositions: {
			list: '/management/curator-dispositions',
			create: '/management/curator-dispositions/create',
			update: '/management/curator-dispositions/:id/edit'
		},
		denominations: {
			list: '/management/denominations',
			create: '/management/denominations/create',
			update: '/management/denominations/:id/edit'
		},
		mints: {
			list: '/management/mints',
			create: '/management/mints/create',
			update: '/management/mints/:id/edit'
		},
		protocols: {
			list: '/management/protocols',
			create: '/management/protocols/create',
			update: '/management/protocols/:id/edit'
		},
		reigns: {
			list: '/management/reigns',
			create: '/management/reigns/create',
			update: '/management/reigns/:id/edit'
		},
		artifactTypes: {
			list: '/management/artifact-types',
			create: '/management/artifact-types/create',
			update: '/management/artifact-types/:id/edit'
		},	
		owners: {
			list: '/management/owners',
			create: '/management/owners/create',
			update: '/management/owners/:id/edit'
		},	
		conservators: {
			list: '/management/conservators',
			create: '/management/conservators/create',
			update: '/management/conservators/:id/edit'
		},	
		grades: {
			list: '/management/grades',
			create: '/management/grades/create',
			update: '/management/grades/:id/edit'
		},
		artifactClasses: {
			list: '/management/artifact-classes',
			create: '/management/artifact-classes/create',
			update: '/management/artifact-classes/:id/edit'
		},
		dispositions: {
			list: '/management/dispositions',
			create: '/management/dispositions/create',
			update: '/management/dispositions/:id/edit'
		},
		pearlGrades: {
			list: '/management/pearl-grades',
			create: '/management/pearl-grades/create',
			update: '/management/pearl-grades/:id/edit'
		},
		chemicalStabilizations: {
			list: '/management/chemical-stabilizations',
			create: '/management/chemical-stabilizations/create',
			update: '/management/chemical-stabilizations/:id/edit'
		},
		artifactGroups: {
			list: '/management/artifact-groups',
			create: '/management/artifact-groups/create',
			update: '/management/artifact-groups/:id/edit'
		},
		chlorideRemovals: {
			list: '/management/chloride-removals',
			create: '/management/chloride-removals/create',
			update: '/management/chlorider-emovals/:id/edit'
		},
		divisionTypes: {
			list: '/management/division-types',
			create: '/management/division-types/create',
			update: '/management/division-types/:id/edit'
		},
		contractTypes: {
			list: '/management/contract-types',
			create: '/management/contract-types/create',
			update: '/management/contract-types/:id/edit'
		},
		investorTypes: {
			list: '/management/investor-types',
			create: '/management/investor-types/create',
			update: '/management/investor-types/:id/edit'
		},
		investorRelationsContacts: {
			list: '/management/investor-relations-contacts',
			create: '/management/investor-relations-contacts/create',
			update: '/management/investor-relations-contacts/:id/edit'
		},
		paymentMethodTypes: {
			list: '/management/payment-method-types',
			create: '/management/payment-method-types/create',
			update: '/management/payment-method-types/:id/edit'
		},
		phoneNumberTypes: {
			list: '/management/phone-number-types',
			create:'/management/phone-number-types/create',
			update: '/management/phone-number-types/:id/edit'
		},
		associateTypes: {
			list: '/management/associate-types',
			create:'/management/associate-types/create',
			update: '/management/associate-types/:id/edit'
		},
		investorLevels: {
			list: '/management/investor-levels',
			create:'/management/investor-levels/create',
			update: '/management/investor-levels/:id/edit'
		},
		conservationResults: {
			list: '/management/conservation-results',
			create:'/management/conservation-results/create',
			update: '/management/conservation-results/:id/edit'
		},
		emailAddressTypes: {
			list: '/management/email-address-types',
			create:'/management/email-address-types/create',
			update: '/management/email-address-types/:id/edit'
		},
		addressTypes: {
			list: '/management/address-types',
			create:'/management/address-types/create',
			update: '/management/address-types/:id/edit'
		},
		chlorideReleaseTests: {
			list: '/management/chloride-release-tests',
			create:'/management/chloride-release-tests/create',
			update: '/management/chloride-release-tests/:id/edit'
		},
		concretionRemovalMethods: {
			list: '/management/concretion-removal-methods',
			create:'/management/concretion-removal-methods/create',
			update: '/management/concretion-removal-methods/:id/edit'
		},
		leadSources: {
			list: '/management/lead-sources',
			create:'/management/lead-sources/create',
			update: '/management/lead-sources/:id/edit'
		},
		blueHoleContractTypes: {
			list: '/management/blue-hole-contract-types',
			create:'/management/blue-hole-contract-types/create',
			update: '/management/blue-hole-contract-types/:id/edit'
		},
		tradeTypes: {
			list: '/management/trade-types',
			create:'/management/trade-types/create',
			update: '/management/trade-types/:id/edit'
		},
		coinDateRemarks: {
			list: '/management/coin-date-remarks',
			create:'/management/coin-date-remarks/create',
			update: '/management/coin-date-remarks/:id/edit'
		},
		artifactDescriptions: {
			list: '/management/artifact-descriptions',
			create: '/management/artifact-descriptions/create',
			update: '/management/artifact-descriptions/:id/edit'
		},
		galleonSections: {
			list: '/management/galleon-sections',
			create: '/management/galleon-sections/create',
			update: '/management/galleon-sections/:id/edit'
		},
		galleonActivityAreas: {
			list: '/management/galleon-activity-areas',
			create: '/management/galleon-activity-areas/create',
			update: '/management/galleon-activity-areas/:id/edit'
		},
		buildings: {
			list: '/management/buildings',
			create: '/management/buildings/create',
			update: '/management/buildings/:id/edit'
		},
		safes: {
			list: '/management/safes',
			create: '/management/safes/create',
			update: '/management/safes/:id/edit'
		},
		shelfs: {
			list: '/management/shelfs',
			create: '/management/shelfs/create',
			update: '/management/shelfs/:id/edit'
		},
		bins: {
			list: '/management/bins',
			create: '/management/bins/create',
			update: '/management/bins/:id/edit'
		}
	},
	error401: '/401',
	error403: '/403',
	error404: '/404',
	error500: '/500'
};
