import { createRef, FC, useEffect, useRef, useState } from 'react';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import {
    Box,
    Button,
    Drawer,
    FormLabel,
    IconButton,
    Stack,
    SvgIcon,
    Typography,
} from '@mui/material';
import { Scrollbar } from 'src/shared/components/scrollbar';
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import { ArtifactTagSearchMode } from 'src/models/artifact';
import { nameof } from 'src/shared/utils/nameof';
import { FormSelect, FormSelectHandle, FormSelectItem, FormSelectItemLabel, toFormSelectItems } from 'src/shared/components/form/form-select';
import { FormTextInput, FormTextInputHandle } from 'src/shared/components/form/form-text-input';
import { FormSwitch, FormSwitchHandle } from 'src/shared/components/form/form-switch';
import { FormMultiSelect, FormMultiSelectHandle } from 'src/shared/components/form/form-multi-select';
import { FormDateRange, FormDateRangeHandle } from 'src/shared/components/form/form-date-range';
import { splitCamelCase } from 'src/shared/utils/split-camel-case';
import { WreckSiteRow } from 'src/models/wreck-site';
import { VesselRow } from 'src/models/vessel';
import { FormFieldHandle } from 'src/shared/components/form/form-field';
import { LookupRow } from 'src/models/lookup';
import SummarizeIcon from '@mui/icons-material/Summarize';

export interface CoinSearchPanelFields {
    recoveryDateStart: Date | null;
    recoveryDateEnd: Date | null;
    tag: string;
    tagSearchMode: ArtifactTagSearchMode;
    operationId: number;
    wreckSiteId: number;
    vesselId: number;
    artifactDescriptionIds: number[];
    highTicketOnly: boolean;
}
export interface CoinSearchPanelDatasources {
    artifactDescriptions: LookupRow[];
    operations: FormSelectItem<number>[];
    wreckSites: WreckSiteRow[];
    vessels: VesselRow[];
    artifactTagSearchModes: FormSelectItem<number>[];
}

interface CoinSearchPanelProps {
    datasources?: CoinSearchPanelDatasources;
    defaultOperationId?: number | null;
    container: HTMLDivElement | null;
    open: boolean;
    onOpenChanged: (open: boolean) => void;
    onSubmit?: (fields: CoinSearchPanelFields) => void;
    onReset?: (fields: CoinSearchPanelFields) => void;
}

const fieldDefaults: CoinSearchPanelFields = {
    tag: '',
    tagSearchMode: ArtifactTagSearchMode.ExactMatch,
    operationId: -1,
    wreckSiteId: -1,
    vesselId: -1,
    artifactDescriptionIds: [],
    recoveryDateStart: null,
    recoveryDateEnd: null,
    highTicketOnly: false
}

export const CoinSearchPanel: FC<CoinSearchPanelProps> = (props) => {
    const defaultSelectOptions = [{ label: "All", value: -1 }]
    const defaultArtifactSelectSelectOptions = [{ label: "All Coins", value: -1 }]
    const {
        container,
        open,
        onOpenChanged,
        datasources = {
            artifactTypes: defaultSelectOptions,
            operations: defaultSelectOptions,
            wreckSites: [],
            vessels: [],
            artifactDescriptions: [],
            artifactTagSearchModes: [{ label: splitCamelCase(ArtifactTagSearchMode[ArtifactTagSearchMode.ExactMatch]), value: ArtifactTagSearchMode.ExactMatch }]
        },
        onSubmit,
        onReset,
        defaultOperationId,
        ...other
    } = props;



    //Refs
    const fieldRefs = {
        tag: createRef<FormTextInputHandle>(),
        tagSearchMode: createRef<FormSelectHandle>(),
        recoveryDate: createRef<FormDateRangeHandle>(),
        operationId: createRef<FormSelectHandle>(),
        wreckSiteId: createRef<FormSelectHandle>(),
        vesselId: createRef<FormSelectHandle>(),
        artifactDescriptionIds: createRef<FormMultiSelectHandle>(),
        highTicketOnly: createRef<FormSwitchHandle>(),
    }

    const fieldValuesRef = useRef<CoinSearchPanelFields>({
        ...fieldDefaults
    });

    //State
    const [wreckSiteItems, setWreckSiteItems] = useState<FormSelectItem<number>[]>(defaultSelectOptions);
    const [vesselItems, setVesselItems] = useState<FormSelectItem<number>[]>(defaultSelectOptions);
    const [artifactDescriptionItems, setArtifactDescriptionItems] = useState<FormSelectItem<number>[]>(defaultArtifactSelectSelectOptions);
    const [operationDefaulted, setOperationDefaulted] = useState<boolean>(false);

    const onFieldValueChanged = (field: string, value: any | null) => {
        //Update ref does not cause unnecessary renders
        const newFields: CoinSearchPanelFields = {
            ...fieldValuesRef.current,
            [field]: value || undefined,
        }
        fieldValuesRef.current = newFields;

        if (field === nameof<CoinSearchPanelFields>("operationId")) {
            if (value === -1) {
                setWreckSiteItems(toFormSelectItems(datasources.wreckSites, FormSelectItemLabel.All));
                setVesselItems(toFormSelectItems(datasources.vessels, FormSelectItemLabel.All));
            } else {
                setWreckSiteItems(toFormSelectItems(datasources.wreckSites.filter(f => f.operationId === value), FormSelectItemLabel.All));
                setVesselItems(toFormSelectItems(datasources.vessels.filter(f => f.operationId === value), FormSelectItemLabel.All));
            }

            fieldRefs.wreckSiteId.current?.setValue(-1);
            fieldRefs.vesselId.current?.setValue(-1);
        }

    };

    const onFormSubmit = () => {
        onSubmit?.(fieldValuesRef.current);
    }

    const onFormReset = () => {

        fieldValuesRef.current = { ...fieldDefaults };
        for (const fieldKey in fieldRefs) {
            const fieldHandle = fieldRefs[fieldKey as keyof typeof fieldRefs] as React.RefObject<FormFieldHandle>;
            fieldHandle?.current?.resetValue();
        }

        onReset?.(fieldValuesRef.current);
    }

    useEffect(() => {
        if (datasources.wreckSites.length > 1) {
            setWreckSiteItems(toFormSelectItems(datasources.wreckSites, FormSelectItemLabel.All));
        }
        if (datasources.vessels.length > 1) {
            setVesselItems(toFormSelectItems(datasources.vessels, FormSelectItemLabel.All));
        }
        if (datasources.artifactDescriptions.length > 1) {
            setArtifactDescriptionItems(toFormSelectItems(datasources.artifactDescriptions, FormSelectItemLabel.Custom, "All Coins"));
        }

    }, [datasources.vessels, datasources.wreckSites, datasources.artifactDescriptions]);

    useEffect(() => {

        if (defaultOperationId && !operationDefaulted && datasources.operations?.length > 1) {
            fieldRefs.operationId.current?.setValue(defaultOperationId);
            setOperationDefaulted(true);
        }

    }, [datasources.operations, defaultOperationId, fieldRefs.operationId, operationDefaulted]);

    return (
        <>
            {!open && (
                <Box
                    sx={{
                        bottom: 0,
                        display: 'flex',
                        left: 0,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        width: '65px',
                        backgroundColor: '#F8F9FA',
                        fontWeight: 600,
                        textTransform: 'uppercase',
                        color: '#2F3746',
                        letterSpacing: '0.5px',
                        fontSize: '12px',

                    }}

                >

                    <Button
                        color="inherit"
                        startIcon={(
                            <SvgIcon>
                                <SearchMdIcon />
                            </SvgIcon>
                        )}
                        onClick={() => onOpenChanged(true)}>
                    </Button>
                </Box>
            )}

            <Drawer
                anchor="left"
                open={open}
                PaperProps={{
                    elevation: 16,
                    sx: {
                        border: 'none',
                        borderRadius: 2.5,
                        overflow: 'hidden',
                        position: 'relative',
                        width: 300
                    }
                }}
                SlideProps={{ container }}
                variant="persistent"
                sx={{ p: 3 }}
                {...other}
            >
                <>
                    <Stack
                        alignItems="center"
                        justifyContent="space-between"
                        direction="row"
                        sx={{ p: 0 }}
                    >

                        <Typography variant="h5" sx={{ pt: 2, pl: 2 }}>
                            Report Criteria
                        </Typography>
                        <Stack alignItems="right" direction="row" justifyContent="end" sx={{ pt: 1, pr: 1 }}>
                            <IconButton onClick={() => onOpenChanged(false)} size="small">
                                <SvgIcon>
                                    <XIcon />
                                </SvgIcon>
                            </IconButton>
                        </Stack>
                    </Stack>
                    <Stack height='78%'>
                        <Scrollbar
                            forceVisible={true}
                            autoHide={false}
                            sx={{
                                mt: 2,
                                height: '100%',
                                width: '96%',
                                '& .simplebar-content': {
                                    height: '100%'
                                },
                                '& .simplebar-scrollbar:before': {
                                    background: '#e7e7e7'
                                }
                            }}
                        >
                            <Stack sx={{ pt: 0, pl: 2, pr: 2 }}>
                                <Stack
                                    spacing={3}
                                    sx={{ p: 1 }}
                                >
                                      <Stack spacing={1}>
                                        <FormLabel
                                            sx={{
                                                mb: 1
                                            }}
                                        >
                                            Coin Types
                                        </FormLabel>
                                    <FormMultiSelect
                                        ref={fieldRefs.artifactDescriptionIds}
                                        label="Artifact Description"
                                        name={nameof<CoinSearchPanelFields>("artifactDescriptionIds")}
                                        initialValue={fieldDefaults.artifactDescriptionIds}
                                        datasource={artifactDescriptionItems}
                                        onChange={onFieldValueChanged} />
                                        </Stack>
                                    <Stack spacing={1}>
                                        <FormLabel
                                            sx={{
                                                mb: 1
                                            }}
                                        >
                                            Tag
                                        </FormLabel>


                                        <FormTextInput
                                            ref={fieldRefs.tag}
                                            label="Tag Number"
                                            name={nameof<CoinSearchPanelFields>("tag")}
                                            initialValue={fieldDefaults.tag}
                                            onChange={onFieldValueChanged} />


                                        <FormSelect
                                            ref={fieldRefs.tagSearchMode}
                                            label="Tag Search Mode"
                                            name={nameof<CoinSearchPanelFields>("tagSearchMode")}
                                            initialValue={fieldDefaults.tagSearchMode}
                                            datasource={datasources.artifactTagSearchModes}
                                            onChange={onFieldValueChanged} />
                                    </Stack>
                  
                                    <div>
                                        <FormLabel
                                            sx={{
                                                display: 'block',
                                                mb: 2
                                            }}
                                        >
                                            Recovery Date
                                        </FormLabel>

                                        <FormDateRange
                                            ref={fieldRefs.recoveryDate}
                                            startlabel="Start"
                                            startName={nameof<CoinSearchPanelFields>("recoveryDateStart")}
                                            initialStartValue={fieldDefaults.recoveryDateStart}
                                            endLabel="End"
                                            endName={nameof<CoinSearchPanelFields>("recoveryDateEnd")}
                                            initialEndValue={fieldDefaults.recoveryDateEnd}
                                            onChange={onFieldValueChanged} />

                                    </div>
                                    <div>
                                        <FormLabel
                                            sx={{
                                                display: 'block',

                                            }}
                                        >
                                           Advanced Filters
                                        </FormLabel>

                                    </div>
                                    <FormSelect
                                        ref={fieldRefs.operationId}
                                        label="Operation"
                                        name={nameof<CoinSearchPanelFields>("operationId")}
                                        initialValue={fieldDefaults.operationId}
                                        datasource={datasources.operations}
                                        onChange={onFieldValueChanged} />

                                    <FormSelect
                                        ref={fieldRefs.wreckSiteId}
                                        label="Wreck Site"
                                        name={nameof<CoinSearchPanelFields>("wreckSiteId")}
                                        initialValue={fieldDefaults.wreckSiteId}
                                        datasource={wreckSiteItems}
                                        onChange={onFieldValueChanged} />

                                    <FormSelect
                                        ref={fieldRefs.vesselId}
                                        label="Vessel"
                                        name={nameof<CoinSearchPanelFields>("vesselId")}
                                        initialValue={fieldDefaults.vesselId}
                                        datasource={vesselItems}
                                        onChange={onFieldValueChanged} />



                   

                                    <FormSwitch
                                        ref={fieldRefs.highTicketOnly}
                                        label="High Ticket Only"
                                        name={nameof<CoinSearchPanelFields>("highTicketOnly")}
                                        initialValue={fieldDefaults.highTicketOnly}
                                        onChange={onFieldValueChanged} />


                                </Stack>
                            </Stack>
                        </Scrollbar>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="right"
                        alignItems="flex-end"
                        sx={{ pt: 3, pr: 3 }}>
                        <IconButton
                            sx={{ mr: 2 }}
                            onClick={onFormReset}
                        >
                            <SvgIcon>
                                <RestartAltOutlinedIcon />
                            </SvgIcon>
                        </IconButton>
                        <Button
                            startIcon={(
                                <SvgIcon>
                                    <SummarizeIcon />
                                </SvgIcon>
                            )}
                            variant="contained"
                            onClick={onFormSubmit}
                        >
                            Run Report
                        </Button>
                    </Stack>
                </>

            </Drawer>
        </>

    );


}
