import type { FC, ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { RouterLink } from 'src/shared/components/router-link';
import { paths } from 'src/routes/paths';

interface LoginLayoutProps {
  children: ReactNode;
}

export const LoginLayout: FC<LoginLayoutProps> = (props) => {
  const { children } = props;

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: {
          xs: 'column-reverse',
          md: 'row'
        }
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'neutral.800',
          backgroundImage: 'url("/assets/gradient-bg.svg")',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          color: 'common.white',
          display: 'flex',
          flex: {
            xs: '0 0 auto',
          justifyContent: 'center',
          p: {
            xs: 4,
            md: '1 1 auto'
          },
            md: 8
          }
        }}
          >

              <Box maxWidth="md">
                  {/* <img src="#"
                      style={{  paddingTop: 40, margin: 'auto' }}/> */}

                  <Typography
                      sx={{ mb: 1, marginTop: 3, textAlign: 'center' }}
            variant="h5"
          >
            Internal Corporate Database
          </Typography>
          <Typography
            color="text.secondary"
                      sx={{ mb: 1, textAlign: 'center' }}
          >
           Authorized Users Only
          </Typography>

        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          flex: {
            xs: '1 1 auto',
            md: '0 0 auto'
          },
          flexDirection: 'column',
          justifyContent: {
            md: 'center'
          },
          maxWidth: '100%',
          p: {
            xs: 4,
            md: 8
          },
          width: {
            md: 600
          }
        }}
      >
        <div>
          <Box sx={{ mb: 4 }}>
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.dashboard}
              spacing={1}
              sx={{ textDecoration: 'none' }}
            >
              {/*<Box*/}
              {/*  sx={{*/}
              {/*    display: 'inline-flex',*/}
              {/*    height: 24,*/}
              {/*    width: 24*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Logo />*/}
              {/*</Box>*/}
         
            </Stack>
          </Box>
          {children}
        </div>
      </Box>
    </Box>
  );
};
