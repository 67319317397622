import { SvgIcon, Typography } from "@mui/material";
import { Stack, Box } from "@mui/system";
import { FC } from "react";
import SummarizeIcon from '@mui/icons-material/Summarize';

export const ReportBanner: FC = () => {
  
    return (
      <Stack
        alignItems="center"
        direction={{
          xs: 'column',
          md: 'row'
        }}
        spacing={4}
        sx={{
          backgroundColor: (theme) => theme.palette.mode === 'dark'
            ? 'primary.darkest'
            : 'primary.lightest',
          borderRadius: 2.5,
          p: 4
        }}
      >
        <Box
          sx={{
            width: 50,
     
          }}
        >
          <SvgIcon style={{ fontSize:72 }}>
                                    <SummarizeIcon />
                                </SvgIcon>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
     
          <Typography
            color="text.primary"
            sx={{ mt: 0 }}
            variant="h6"
          >
            Select Report Criteria
          </Typography>
          <Typography
            color="text.primary"
            sx={{ mt: 1 }}
            variant="body1"
          >
            Use the panel on the left to select critera and run this report.
          </Typography>

        </Box>
      </Stack>
    );
  };