import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, Drawer, IconButton, Link, Stack, SvgIcon, Typography } from '@mui/material';
import { ArtifactModel } from 'src/models/artifact';
import { ApiQuery } from 'src/api/api-query';
import { Api } from 'src/api/api';
import { GetArtifactQuery, GetArtifactQueryResult } from 'src/api/queries/artifact/get-artifact';
import { ArtifactView } from './artifact-view';
import Edit02Icon from '@untitled-ui/icons-react/build/esm/Edit02';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { RouterLink } from 'src/shared/components/router-link';
import { UIProgress } from 'src/shared/components/ui/ui-progress';
import { useAuth } from 'src/shared/hooks/use-auth';
import { Permission } from 'src/models/permission';
import { GetArtifactFilesQuery, GetArtifactFilesQueryResult } from 'src/api/queries/artifact-file/get-artifact-files';
import { ArtifactFileCategory, ArtifactFileType, ArtifactFileTypeDisplayNames, ArtifactImage } from 'src/models/artifact-file';
import { ArtifactDetailTags } from './artifact-tags';
import { GetArtifactTransfersQuery, GetArtifactTransfersQueryResult } from 'src/api/queries/artifact-transfer/get-artifact-transfers';
import { ArtifactTransferRow } from 'src/models/artifact-transfer';
import { GetArtifactCertificatesQuery, GetArtifactCertificatesQueryResult } from 'src/api/queries/artifact-certificate/get-artifact-certificates';
import { ArtifactCertificateModel, ArtifactCertificateRow } from 'src/models/artifact-certificate';
import { ArtifactImageNotAvailable } from './artifact-image-unavailable';
import ArrowLeftIcon from '@untitled-ui/icons-react/build/esm/ArrowLeft';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router';


interface ArtifactDrawerProps {
  container?: HTMLDivElement | null;
  open?: boolean;
  onClose?: () => void;
  artifactId?: number;
  searchResultArtifactIds?: number[];
}

export const ArtifactDrawer: FC<ArtifactDrawerProps> = (props) => {
  const navigate = useNavigate();
  const { container, onClose, open, artifactId } = props;
  const auth = useAuth();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [closeDrawerRequired, setCloseDrawerRequired] = useState<boolean>(false);
  const [artifact, setArtifact] = useState<ArtifactModel>();
  const [artifactTransfers, setArtifactTransfers] = useState<ArtifactTransferRow[]>([]);
  const [artifactCertificates, setArtifactCertificates] = useState<ArtifactCertificateRow[]>([]);
  const [artifactImages, setArtifactImages] = useState<ArtifactImage[]>([]);
  const searchResultsCurrentIndex = props.searchResultArtifactIds?.indexOf(props.artifactId || -1) ?? -1;
  const searchResultsHasPrevious = searchResultsCurrentIndex > 0;
  const searchResultsHasNext = (props.searchResultArtifactIds?.length || 0) > searchResultsCurrentIndex + 1;
  const searchResultsCurrentDisplay = searchResultsCurrentIndex + 1;
  const searchResultsLength = props.searchResultArtifactIds?.length || 0;

  const editButtonEnabled = auth.authState.principal?.hasPermission(Permission.ArtifactsEdit) || false;
  const manageImagesEnabled = auth.authState.principal?.hasAnyPermission([Permission.ArtifactsCreateImages, Permission.ArtifactsDeleteImages]) || false;
  const issueArtifactCertificatesEnabled = auth.authState.principal?.hasPermission(Permission.ArtifactsIssueCertificates) || false;

  const handleEditOpen = () => {
    setIsEditing(true);
  };

  const handleDrawerClose = () => {
    setArtifact(undefined);
    setArtifactImages([])
    setIsEditing(false);
    onClose?.();
  };

  const handleNextSearchResult = (): void => {
    if (!searchResultsHasNext) {
      return;
    }

    const nextId = props.searchResultArtifactIds?.[searchResultsCurrentIndex + 1];

    if (nextId) {
      setArtifact(undefined);
      navigate('#' + nextId);
    }

  };

  const handlePreviousSearchResult = (): void => {
    if (!searchResultsHasPrevious) {
      return;
    }

    const nextId = props.searchResultArtifactIds?.[searchResultsCurrentIndex - 1];

    if (nextId) {
      setArtifact(undefined);
      navigate('#' + nextId);
    }

  };

  const handleArtifactCertificateIssued = (certificate: ArtifactCertificateModel) => {
    setArtifactCertificates([
      { ...certificate, createdByUserName: auth.authState?.principal?.emailAddress },
      ...artifactCertificates
    ])
  };


  useEffect(() => {
    let loadCanceled = false;

    async function load() {

      if (!artifactId) {
        setArtifact(undefined);
        setArtifactImages([]);
        setArtifactTransfers([]);
        setArtifactCertificates([]);
        return;
      }

      const getArtifactQueryResult = await Api.executeQuery<GetArtifactQuery, GetArtifactQueryResult>(
        ApiQuery.GetArtifact, { id: artifactId }
      );

      const getArtifactTransfersQueryResult = await Api.executeQuery<GetArtifactTransfersQuery, GetArtifactTransfersQueryResult>(
        ApiQuery.GetArtifactTransfers, { artifactId }
      );

      const getArtifactCertificatesQueryResult = await Api.executeQuery<GetArtifactCertificatesQuery, GetArtifactCertificatesQueryResult>(
        ApiQuery.GetArtifactCertificates, { artifactId }
      );

      if (loadCanceled || !getArtifactQueryResult.record) {
        setCloseDrawerRequired(true);
        return;
      }

      setArtifact(getArtifactQueryResult.record);
      setArtifactTransfers(getArtifactTransfersQueryResult.rows);
      setArtifactCertificates(getArtifactCertificatesQueryResult.rows);

    }

    const loadImages = async () => {

      if (!artifactId) {
        setArtifactImages([]);
        return;
      }

      const images: ArtifactImage[] = [];
      let data: any;

      const artifactImageFiles = (await Api.executeQuery<GetArtifactFilesQuery, GetArtifactFilesQueryResult>(
        ApiQuery.GetArtifactFiles, { artifactId, category: ArtifactFileCategory.Image }
      )).rows;

      //Order default (if exists otherwise hide), obverse, reverse, pre, additional (array)
      for (const modelType of [ArtifactFileType.ImageDefault, ArtifactFileType.ImageObverse, ArtifactFileType.ImageReverse, ArtifactFileType.ImagePreConservation]) {
        const model = artifactImageFiles?.find(f => f.type === modelType);
        data = model ? await Api.getArtifactImageData(model.artifactId, model.id) : ArtifactImageNotAvailable

        if(modelType === ArtifactFileType.ImageDefault && data === ArtifactImageNotAvailable) {
          continue;
        }

        images.push({
          data,
          model,
          label: ArtifactFileTypeDisplayNames[modelType]
        });
       
     
      }


      const additionalModelType = ArtifactFileType.ImageAdditional;
      const additionalModels = artifactImageFiles?.filter(f => f.type === ArtifactFileType.ImageAdditional) || [];
      for (const model of additionalModels) {
        data = await Api.getArtifactImageData(model.artifactId, model.id);
        images.push({
          data,
          model,
          label: ArtifactFileTypeDisplayNames[additionalModelType]
        });

      }

      setArtifactImages(images);
    };

    load();
    loadImages();

    return () => {
      loadCanceled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artifactId]);


  useEffect(() => {
    if (closeDrawerRequired === false) {
      return; // No action
    }

    setArtifact(undefined);
    setArtifactImages([]);
    setArtifactTransfers([]);
    handleDrawerClose();
    setCloseDrawerRequired(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeDrawerRequired])

  return (
    <Drawer
      anchor="right"

      open={open}
      PaperProps={{
        sx: {
          position: 'absolute',
          width: '100%',
          pointerEvents: 'auto',

        }
      }}
      SlideProps={{ container }}
      variant="persistent"
      hideBackdrop={true}
      ModalProps={{
        container,
        sx: {
          pointerEvents: 'none',
          position: 'absolute'
        }
      }}
      onClose={onClose}
    >

      <>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            px: 3,
            py: 0
          }}
        >
          <Stack spacing={2}>
            {searchResultsCurrentDisplay > 0 ? (
              <div>
                <Link
                  color="text.primary"
                  onClick={handleDrawerClose}
                  sx={{
                    alignItems: 'center',
                    display: 'inline-flex',
                    cursor: 'pointer',
                    paddingTop: '12px'
                  }}
                  underline="hover"
                >
                  <SvgIcon sx={{ mr: 0 }}>
                    <ArrowLeftIcon />
                  </SvgIcon>
                  <Typography variant="subtitle2">
                    Search Results
                  </Typography>
                </Link>
                <Link
                  color="text.secondary"
                  sx={{
                    alignItems: 'center',
                    display: 'inline-flex',
                    cursor: 'arrow',
                    paddingTop: '12px',
                    textDecoration: 'none'
                  }}
                  underline="none"
                >
                  <SvgIcon sx={{ mr: 1, }}>

                  </SvgIcon>
                  <Typography variant="subtitle2">
                    {searchResultsCurrentDisplay} of {searchResultsLength}
                  </Typography>
                  <SvgIcon sx={{ mr: 1 }}>

                  </SvgIcon>
                </Link>
                <Link
                  color="text.primary"
                  onClick={handlePreviousSearchResult}
                  sx={{
                    alignItems: 'center',
                    display: 'inline-flex',
                    marginRight: '5px',
                    paddingTop: '12px'
                  }}

                  
                  underline="hover"
                  aria-disabled={!searchResultsHasPrevious}
                >
                  <SvgIcon sx={{ ml: 1, cursor: 'pointer' }}>
                    <NavigateBeforeIcon />
                  </SvgIcon>

                </Link>
                <Link
                  color="text.primary"
                  onClick={handleNextSearchResult}
                  sx={{
                    alignItems: 'center',
                    display: 'inline-flex',

                    paddingTop: '12px'
                  }}
                  underline="hover"
                  aria-disabled={!searchResultsHasNext}
                >
                  <SvgIcon sx={{ mr: 1, cursor: 'pointer' }}>
                    <NavigateNextIcon />
                  </SvgIcon>

                </Link>
              </div>
            ) : (
              <div>
              <Link
                color="text.primary"
                onClick={handleDrawerClose}
                sx={{
                  alignItems: 'center',
                  display: 'inline-flex',
                  cursor: 'pointer',
                  paddingTop: '12px'
                }}
                underline="hover"
              >
                <SvgIcon sx={{ mr: 0 }}>
                  <ArrowLeftIcon />
                </SvgIcon>
                <Typography variant="subtitle2">
                  Search Artifacts
                </Typography>
              </Link>
              </div>
            )}

            <Typography variant="h4">
              Artifact Details
            </Typography>

          </Stack>

          <IconButton
            color="inherit"
            onClick={handleDrawerClose}

          >
            <SvgIcon>
              <XIcon fontSize={18} />
            </SvgIcon>
          </IconButton>



        </Stack>

        {!artifact ?
          (
            <UIProgress />
          ) : (
            <>
              <Stack
                sx={{
                  px: 3,
                  py: 2
                }}>

                <Stack
                  alignItems="flex-start"
                  direction={{
                    xs: 'column',
                    md: 'row'
                  }}
                  justifyContent="space-between"
                  spacing={4}
                >

                  <ArtifactDetailTags artifact={artifact} />
                  <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                  >
                    <Button
                      disabled={!editButtonEnabled}
                      color="inherit"
                      component={RouterLink}
                      startIcon={(
                        <SvgIcon>
                          <Edit02Icon />
                        </SvgIcon>
                      )}
                      href={`/artifacts/${artifact?.id}/edit`}
                    >
                      Edit
                    </Button>
                    <Button
                      disabled={!manageImagesEnabled}
                      color="inherit"
                      component={RouterLink}
                      startIcon={(
                        <SvgIcon>
                          <Edit02Icon />
                        </SvgIcon>
                      )}
                      href={`/artifacts/${artifact?.id}/images`}
                    >
                      Manage Images
                    </Button>
                    {/* <Button
                  endIcon={(
                    <SvgIcon>
                      <ChevronDownIcon />
                    </SvgIcon>
                  )}
                  variant="contained"
                >
                  Actions
                </Button> */}
                  </Stack>
                </Stack>

              </Stack>

              <Box
                sx={{
                  px: 3,
                  py: 0
                }}
              >
                {
                  !isEditing
                    ? (
                      <ArtifactView
                        onEdit={handleEditOpen}
                        artifact={artifact}
                        transfers={artifactTransfers}
                        images={artifactImages}
                        certificates={artifactCertificates}
                        onCertificateIssued={handleArtifactCertificateIssued}
                        issueCertificatesEnabled={issueArtifactCertificatesEnabled}

                      />
                    )
                    : (
                      <></>
                      // <ArtifactEdit
                      //   onCancel={handleEditCancel}
                      //   onSave={handleEditCancel}
                      //   Artifact={Artifact}
                      // />
                    )
                }
              </Box>
            </>
          )}
      </>

    </Drawer>
  );
};
