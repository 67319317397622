import type { FC } from 'react';
import { Fragment, useState } from 'react';
import { Backdrop, CircularProgress, Dialog, DialogContent, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import UIMessage from 'src/shared/components/ui/ui-message';
import { ArtifactDetailTags } from './artifact-tags';
import { ArtifactModel } from 'src/models/artifact';
import {
    PDFViewer,
    Page,
    Document,
    View,
    Font,
    StyleSheet,
    Text,
    Image,
} from "@react-pdf/renderer";
import { ArtifactFileType, ArtifactImage } from 'src/models/artifact-file';
import { useMount } from 'src/shared/hooks/use-mount';
import ArtifactCertificateForm, { ArtifactCertificateFormDatasources, ArtifactCertificateFormFields } from './artifact-certificate-form';
import { ApiQuery } from 'src/api/api-query';
import { getLookupFormSelectItems, LookupFormSelectItemLabel } from 'src/shared/utils/get-lookup-select-items';
import { useAuth } from 'src/shared/hooks/use-auth';
import { Permission } from 'src/models/permission';
import { CreateArtifactCertificateCommand } from 'src/api/commands/artifact-certificate';
import { ApiCommand, CreateCommandResult } from 'src/api/api-command';
import { Api } from 'src/api/api';
import { GetArtifactCertificateQuery, GetArtifactCertificateQueryResult } from 'src/api/queries/artifact-certificate/get-artifact-certificate';
import { ArtifactCertificateModel } from 'src/models/artifact-certificate';
import { WreckSite } from 'src/models/wreck-site';
import { coinDescriptionIds, emeraldDescriptionIds, silverIngotDescriptionIds } from 'src/models/artifact-description';
import { ArtifactImageNotAvailable } from './artifact-image-unavailable';


interface ArtifactCertificateIssuerProps {
    artifact: ArtifactModel;
    artifactImages: ArtifactImage[];
    onCertificateIssued: (certificate: ArtifactCertificateModel) => void;
    onClose: () => void;
    open?: boolean;
}

export const ArtifactCertificateIssuer: FC<ArtifactCertificateIssuerProps> = ({ onClose, artifact, artifactImages, onCertificateIssued, open = false }) => {
    const auth = useAuth();
    const [errors, setErrors] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [formDatasources, setFormDatasources] = useState<ArtifactCertificateFormDatasources>();
    const [certificate, setCertificate] = useState<ArtifactCertificateModel>();


    useMount(() => {
        reset();

        if (!auth.authState.principal?.hasPermission(Permission.ArtifactsIssueCertificates)) {
            onClose()
            return;
        }

        const load = async () => {
            const curatorDispostions = await getLookupFormSelectItems(ApiQuery.GetCuratorDispositions, LookupFormSelectItemLabel.None);
            setFormDatasources({ curatorDispositions: curatorDispostions })
        }

        load();
    });


    const handleFormSubmit = async (fields: ArtifactCertificateFormFields): Promise<void> => {
        const { securitySticker, ...rest } = fields;
        const artifactId = artifact.id;

        setIsSubmitting(true);
        try {
            const command: CreateArtifactCertificateCommand = {
                securitySticker: +securitySticker,
                artifactId,
                ...rest
            };

            const commandResult = await Api.executeCommand<CreateArtifactCertificateCommand, CreateCommandResult>(
                ApiCommand.CreateArtifactCertificate,
                command);

            const queryResult = await Api.executeQuery<GetArtifactCertificateQuery, GetArtifactCertificateQueryResult>(
                ApiQuery.GetArtifactCertificate, { artifactId, certificateId: commandResult.id }
            );

            setCertificate(queryResult.record);

            onCertificateIssued(queryResult.record);

        } catch (err) {
            setErrors(err.response.data.errors || [err.response.statusText])
        }

        setIsSubmitting(false);

    }


    const handleClose = (): void => {
        setErrors([]);
        setIsSubmitting(false);
        setCertificate(undefined);
        onClose();
    };

    const reset = () => {
        setErrors([]);
        setIsSubmitting(false);
        setFormDatasources(undefined);
        setCertificate(undefined);
    };


    return (

        <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: 100 }}
                open={isSubmitting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack spacing={3}
                sx={{
                    px: 3,
                    py: 2
                }}>
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between">

                    <Typography variant="h5">
                        Issue Artifact Certificate
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={handleClose}
                        disabled={isSubmitting}
                    >
                        <SvgIcon>
                            <XIcon />
                        </SvgIcon>
                    </IconButton>
                </Stack>
                <ArtifactDetailTags artifact={artifact} />
            </Stack>

            <DialogContent>
                <UIMessage
                    type="error"
                    messages={errors}
                    onClose={() => setErrors([])} />
                <Stack spacing={2}>

                    {!certificate && formDatasources && (
                        <ArtifactCertificateForm datasources={formDatasources} onSubmit={handleFormSubmit} />
                    )}

                    {certificate && (
                        <ArtifiactCertificateViewer artifact={artifact} artifactImages={artifactImages} certificate={certificate} />
                    )}
                </Stack>


            </DialogContent>
        </Dialog>

    );
};


interface ArtifiactCertificateViewerProps {
    artifact: ArtifactModel;
    artifactImages: ArtifactImage[];
    certificate: ArtifactCertificateModel;
}

const ArtifiactCertificateViewer: FC<ArtifiactCertificateViewerProps> = ({ artifact, artifactImages, certificate }) => {

    const obverseImage = artifactImages.find(f => f.model?.type === ArtifactFileType.ImageObverse);
    const reverseImage = artifactImages.find(f => f.model?.type === ArtifactFileType.ImageReverse);
    const defaultImage = artifactImages.find(f => f.model?.type === ArtifactFileType.ImageDefault);

    const [CertificateContent, backgroundImageSource] = (() => {

        const wreckSiteId = artifact.wreckSiteId ?? -1;
        const artifactDescriptionId = artifact.artifactDescriptionId ?? -1;
        let certificateContent: FC<ArtifactCertificateContentProps> | undefined;
        let wreckSiteName: string = "other";
        let certificateType: string = "artifact";


        switch (wreckSiteId) {
            case WreckSite.Atocha:

                wreckSiteName = "atocha";
                if (coinDescriptionIds.includes(artifactDescriptionId)) {
                    certificateContent = AtochaCoinCertificateContent;
                    certificateType = "coin";
                } else if (emeraldDescriptionIds.includes(artifactDescriptionId)) {
                    certificateContent = AtochaEmeraldCertificateContent;
                    certificateType = "emerald";
                } else if (silverIngotDescriptionIds.includes(artifactDescriptionId)) {
                    certificateContent = AtochaSilverIngotCertificateContent;
                    certificateType = "silver";
                } else {
                    certificateContent = AtochaArtifactCertificateContent;
                    certificateType = "artifact";
                }

                break;
            case WreckSite.Margarita:

                wreckSiteName = "margarita";
                if (coinDescriptionIds.includes(artifactDescriptionId)) {
                    certificateContent = MargaritaCoinCertificateContent;
                    certificateType = "coin";
                } else if (emeraldDescriptionIds.includes(artifactDescriptionId)) {
                    certificateContent = MargaritaEmeraldCertificateContent;
                    certificateType = "emerald";
                } else if (silverIngotDescriptionIds.includes(artifactDescriptionId)) {
                    certificateContent = MargaritaSilverIngotCertificateContent;
                    certificateType = "silver";
                } else {
                    certificateContent = MargaritaArtifactCertificateContent;
                    certificateType = "artifact";
                }

                break;

            case WreckSite.Fleet1715:
            case WreckSite.Site1715:

                wreckSiteName = "1715";
                if (coinDescriptionIds.includes(artifactDescriptionId)) {
                    certificateContent = Fleet1715CoinCertificateContent;
                    certificateType = "coin";
                } else{
                    certificateContent = Fleet1715ArtifactCertificateContent;
                    certificateType = "artifact";
                }

                break;

        }

        return [certificateContent, `/assets/reports/coa/coa-${wreckSiteName}-${certificateType}.png`];
    })();

    useMount(() => {
        Font.register({
            family: "Patrick",
            src: "/assets/fonts/patrick.ttf",
        });
    });

    const styles = StyleSheet.create({
        container: {
            display: "flex",
            position: "absolute",
            width: "100%",
        },
        backgroundContainer: {
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
        },
        backgroundImage: {
            height: "100%",
            width: "100%",
        },
        page: {
            fontSize: 10,
            fontWeight: "normal",
            color: '#000',
            fontFamily: "Times-Roman",
        },
        securityStickerNumber: {
            position: "absolute",
            top: 26,
            right: 12,
            width: 48,
            fontSize: 9,
        }
    });
    return (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
            <Document>

                <Page
                    size="LETTER"
                    orientation="landscape"
                    style={styles.page}
                >
                    {CertificateContent ?
                        <>
                            <View style={styles.backgroundContainer}>
                                <Image src={backgroundImageSource} style={styles.backgroundImage} />
                            </View>
                            <Text style={styles.securityStickerNumber}>{certificate.securitySticker}</Text>
                            <CertificateContent artifact={artifact} certificate={certificate} obverseImage={obverseImage} reverseImage={reverseImage} defaultImage={defaultImage} />
                        </>
                        : <View><Text>Certifcate not yet supported for this wreck site and artifact type.</Text></View>}

                </Page>
            </Document>
        </PDFViewer>
    );

}

interface ArtifactCertificateContentProps {
    artifact: ArtifactModel;
    certificate: ArtifactCertificateModel;
    obverseImage?: ArtifactImage;
    reverseImage?: ArtifactImage;
    defaultImage?: ArtifactImage;
}

interface ArtifactDetailsTableData {
    label: string;
    value: any;
}

const ArtifactDetailsTable: FC<{data: ArtifactDetailsTableData[]}> = ({data}) => {

    const styles = StyleSheet.create({
        tableContainer: {
            flexDirection: "row",
            flexWrap: "wrap",
            position: "absolute",
            top: 19,
            left: 16,
            width: 225,
        },
        row: {
            flexDirection: "row",
            alignItems: "flex-start",
            paddingBottom:1,
        },
        label: {
            width: "75px",
            fontFamily: "Patrick",
            fontWeight: "heavy",
        },
        value: {
            width: "150px",
        }
    });

    const rows = data.map((item, index) => (
        <View style={styles.row} key={index}>
            <Text style={styles.label}>{item.label}</Text>
            <Text style={styles.value}>{item.value}</Text>
        </View>
    ));

    return <View style={styles.tableContainer}>{rows}</View>;
};

const formatRecoveryDate = (dt: Date | undefined) => {
    if (!dt) {
        return ""
    }
    const date = new Date(dt)
    const month: string = (date.getMonth() + 1).toString().padStart(2, '0');
    const day: string = date.getDate().toString().padStart(2, '0');
    const year: number = date.getFullYear();
    return `${month}/${day}/${year}`;
};

const formatCertifiedOnDate = (dt: Date | undefined) => {
    if (!dt) {
        return ""
    }
    const date = new Date(dt)
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    return date.toLocaleDateString('en-US', options);
};

const certificateContentStyles = StyleSheet.create({
    obverseImageLabel: {
        position: "absolute",
        top: 150,
        left: 65,
        width: 100,
        textAlign:'center',
        fontFamily: "Patrick",
        fontWeight: "heavy"
    },
    reverseImageLabel: {
        position: "absolute",
        top: 290,
        left: 65,
        width: 100,
        textAlign:'center',
        fontFamily: "Patrick",
        fontWeight: "heavy"
    },
    obverseImage: {
        position: "absolute",
        top: 165,
        left: 65,
        height: 125,
    },
    reverseImage: {
        position: "absolute",
        top: 305,
        left: 65,
        height: 125,
    },

});


const AtochaCoinCertificateContent: FC<ArtifactCertificateContentProps> = ({ artifact, certificate, obverseImage, reverseImage }) => {

    const styles = StyleSheet.create({
        certifiedOn: {
            position: "absolute",
            bottom: 70,
            left: 275,
            width: 500,
            fontFamily: "Patrick",
            fontWeight: "heavy"
        }
    });

    const data:ArtifactDetailsTableData[] = [
        {label: "Coin Number:", value: artifact.coinNumber},
        {label: "Denomination:", value: artifact.denominationName},
        {label: "Reign:", value: artifact.reignName},
        {label: "Mint:", value: artifact.mintName},
        {label: "Assayer:", value: artifact.assayerName},
        {label: "Weight:", value: artifact.postWeightGm},
        {label: "Grade:", value: artifact.gradeName},
        {label: "Coin Date:", value: artifact.coinDate || "Not Visible"},
        {label: "Comments:", value: artifact.certificateComments || ""}
    ];

    return (
        <>
         
            <ArtifactDetailsTable data={data} />
        
            <Text style={certificateContentStyles.obverseImageLabel}>Obverse View:</Text>
            <Image
                src={`data:image/jpg;base64,'${obverseImage?.data || ArtifactImageNotAvailable}'`}
                style={certificateContentStyles.obverseImage} />

            <Text style={certificateContentStyles.reverseImageLabel}>Reverse View:</Text>
            <Image
                src={`data:image/jpg;base64,'${reverseImage?.data || ArtifactImageNotAvailable}'`}
                style={certificateContentStyles.reverseImage} />


            <Text style={styles.certifiedOn}>This artifact was {certificate.isRecertification ? "re-certifed" : "certifed"} on {formatCertifiedOnDate(certificate.createdUtc)}.</Text>
        </>
    );

}

const AtochaEmeraldCertificateContent: FC<ArtifactCertificateContentProps> = ({ artifact, certificate, obverseImage, defaultImage }) => {

    const styles = StyleSheet.create({
        certifiedOn: {
            position: "absolute",
            bottom: 91,
            left: 265,
            width: 500,
            fontFamily: "Patrick",
            fontWeight: "heavy"
        },
        defaultImage: {
            position: "absolute",
            top: 190,
            left: 50,
            width: 175,
        }
    });

    const data:ArtifactDetailsTableData[] = [
        {label: "Tag Number:", value: artifact.tag},
        {label: "Recovery Vessel:", value: artifact.vesselName},
        {label: "Recovery Date:", value: formatRecoveryDate(artifact.recoveryDate)},
        {label: "Description:", value: artifact.artifactDescriptionName},
        {label: "Class:", value: artifact.artifactClassName},
        {label: "Carat:", value: artifact.carat},
        {label: "Comments:", value: artifact.certificateComments || ""}
    ];

    return (
        <>
         
            <ArtifactDetailsTable data={data} />
        
            <Image
                src={`data:image/jpg;base64,'${defaultImage?.data || obverseImage?.data || ArtifactImageNotAvailable}'`}
                style={styles.defaultImage} />


            <Text style={styles.certifiedOn}>This artifact was {certificate.isRecertification ? "re-certifed" : "certifed"} on {formatCertifiedOnDate(certificate.createdUtc)}.</Text>
        </>
    );

}

const AtochaSilverIngotCertificateContent: FC<ArtifactCertificateContentProps> = ({ artifact, certificate, obverseImage, defaultImage }) => {

    const styles = StyleSheet.create({
        certifiedOn: {
            position: "absolute",
            bottom: 50,
            left: 265,
            width: 500,
            fontFamily: "Patrick",
            fontWeight: "heavy"
        },
        defaultImage: {
            position: "absolute",
            top: 195,
            left: 50,
            width: 130,
        }
    });

    const data:ArtifactDetailsTableData[] = [
        {label: "Tag Number:", value: artifact.tag},
        {label: "TSI Number:", value: artifact.tsiNumber},
        {label: "Recovery Vessel:", value: artifact.vesselName},
        {label: "Recovery Date:", value: formatRecoveryDate(artifact.recoveryDate)},
        {label: "Description:", value: artifact.artifactDescriptionName},
        {label: "Manifest:", value: artifact.manifest},
        {label: "Class Factor:", value: artifact.classFactor},
        {label: "Measurements:", value: artifact.measurements},
        {label: "Karats:", value: artifact.karats},
        {label: "Weight-Troy:", value: (artifact.weightTroyPounds ? artifact.weightTroyPounds + " Pounds" : "") + " " + (artifact.weightTroyOunces ? artifact.weightTroyOunces + " Ounces" : "")},
        {label: "Comments:", value: artifact.certificateComments || ""}
    ];

    return (
        <>
        <ArtifactDetailsTable data={data} />
        
        <Image
            src={`data:image/jpg;base64,'${defaultImage?.data || obverseImage?.data || ArtifactImageNotAvailable}'`}
            style={styles.defaultImage} />


        <Text style={styles.certifiedOn}>This artifact was {certificate.isRecertification ? "re-certifed" : "certifed"} on {formatCertifiedOnDate(certificate.createdUtc)}.</Text>
      </>
    );
}

const AtochaArtifactCertificateContent: FC<ArtifactCertificateContentProps> = ({ artifact, certificate, obverseImage, defaultImage }) => {

    const styles = StyleSheet.create({
        certifiedOn: {
            position: "absolute",
            bottom: 102,
            left: 265,
            width: 500,
            fontFamily: "Patrick",
            fontWeight: "heavy"
        },
        defaultImage: {
            position: "absolute",
            top: 145,
            left: 50,
            width: 175,
        }
    });

    const data:ArtifactDetailsTableData[] = [
        {label: "Tag Number:", value: artifact.tag},
        {label: "Recovery Vessel:", value: artifact.vesselName},
        {label: "Recovery Date:", value: formatRecoveryDate(artifact.recoveryDate)},
        {label: "Description:", value: artifact.artifactDescriptionName},
        {label: "Comments:", value: artifact.certificateComments || ""}
    ];

    return (
        <>
        <ArtifactDetailsTable data={data} />
        
        <Image
            src={`data:image/jpg;base64,'${defaultImage?.data || obverseImage?.data || ArtifactImageNotAvailable}'`}
            style={styles.defaultImage} />


        <Text style={styles.certifiedOn}>This artifact was {certificate.isRecertification ? "re-certifed" : "certifed"} on {formatCertifiedOnDate(certificate.createdUtc)}.</Text>
      </>
    );
}

const MargaritaCoinCertificateContent: FC<ArtifactCertificateContentProps> = ({ artifact, certificate, obverseImage, reverseImage }) => {

    const styles = StyleSheet.create({
        certifiedOn: {
            position: "absolute",
            bottom: 100,
            left: 265,
            width: 500,
            fontFamily: "Patrick",
            fontWeight: "heavy"
        },
    });

    const data:ArtifactDetailsTableData[] = [
        {label: "Coin Number:", value: artifact.coinNumber},
        {label: "Denomination:", value: artifact.denominationName},
        {label: "Reign:", value: artifact.reignName},
        {label: "Mint:", value: artifact.mintName},
        {label: "Assayer:", value: artifact.assayerName},
        {label: "Weight:", value: artifact.postWeightGm},
        {label: "Grade:", value: artifact.gradeName},
        {label: "Coin Date:", value: artifact.coinDate || "Not Visible"},
        {label: "Comments:", value: artifact.certificateComments || ""}
    ];

    return (
        <>
         
            <ArtifactDetailsTable data={data} />
        
            <Text style={certificateContentStyles.obverseImageLabel}>Obverse View:</Text>
            <Image
                src={`data:image/jpg;base64,'${obverseImage?.data || ArtifactImageNotAvailable}'`}
                style={certificateContentStyles.obverseImage} />

            <Text style={certificateContentStyles.reverseImageLabel}>Reverse View:</Text>
            <Image
                src={`data:image/jpg;base64,'${reverseImage?.data || ArtifactImageNotAvailable}'`}
                style={certificateContentStyles.reverseImage} />


            <Text style={styles.certifiedOn}>This artifact was {certificate.isRecertification ? "re-certifed" : "certifed"} on {formatCertifiedOnDate(certificate.createdUtc)}.</Text>
        </>
    );

}

const MargaritaEmeraldCertificateContent: FC<ArtifactCertificateContentProps> = ({ artifact, certificate, obverseImage, defaultImage }) => {

    const styles = StyleSheet.create({
        certifiedOn: {
            position: "absolute",
            bottom: 91,
            left: 265,
            width: 500,
            fontFamily: "Patrick",
            fontWeight: "heavy"
        },
        defaultImage: {
            position: "absolute",
            top: 190,
            left: 50,
            width: 175,
        }
    });

    const data:ArtifactDetailsTableData[] = [
        {label: "Tag Number:", value: artifact.tag},
        {label: "Recovery Vessel:", value: artifact.vesselName},
        {label: "Recovery Date:", value: formatRecoveryDate(artifact.recoveryDate)},
        {label: "Description:", value: artifact.artifactDescriptionName},
        {label: "Class:", value: artifact.artifactClassName},
        {label: "Carat:", value: artifact.carat},
        {label: "Comments:", value: artifact.certificateComments || ""}
    ];

    return (
        <>
         
            <ArtifactDetailsTable data={data} />
        
            <Image
                src={`data:image/jpg;base64,'${defaultImage?.data || obverseImage?.data || ArtifactImageNotAvailable}'`}
                style={styles.defaultImage} />


            <Text style={styles.certifiedOn}>This artifact was {certificate.isRecertification ? "re-certifed" : "certifed"} on {formatCertifiedOnDate(certificate.createdUtc)}.</Text>
        </>
    );

}


const MargaritaSilverIngotCertificateContent: FC<ArtifactCertificateContentProps> = ({ artifact, certificate, obverseImage, defaultImage }) => {

    const styles = StyleSheet.create({
        certifiedOn: {
            position: "absolute",
            bottom: 110,
            left: 265,
            width: 500,
            fontFamily: "Patrick",
            fontWeight: "heavy"
        },
        defaultImage: {
            position: "absolute",
            top: 195,
            left: 50,
            width: 130,
        }
    });

    const data:ArtifactDetailsTableData[] = [
        {label: "Tag Number:", value: artifact.tag},
        {label: "TSI Number:", value: artifact.tsiNumber},
        {label: "Recovery Vessel:", value: artifact.vesselName},
        {label: "Recovery Date:", value: formatRecoveryDate(artifact.recoveryDate)},
        {label: "Description:", value: artifact.artifactDescriptionName},
        {label: "Manifest:", value: artifact.manifest},
        {label: "Class Factor:", value: artifact.classFactor},
        {label: "Measurements:", value: artifact.measurements},
        {label: "Karats:", value: artifact.karats},
        {label: "Weight-Troy:", value: (artifact.weightTroyPounds ? artifact.weightTroyPounds + " Pounds" : "") + " " + (artifact.weightTroyOunces ? artifact.weightTroyOunces + " Ounces" : "")},
        {label: "Comments:", value: artifact.certificateComments || ""}
    ];



    return (
        <>
        <ArtifactDetailsTable data={data} />
        
        <Image
            src={`data:image/jpg;base64,'${defaultImage?.data || obverseImage?.data || ArtifactImageNotAvailable}'`}
            style={styles.defaultImage} />


        <Text style={styles.certifiedOn}>This artifact was {certificate.isRecertification ? "re-certifed" : "certifed"} on {formatCertifiedOnDate(certificate.createdUtc)}.</Text>
      </>
    );
}


const MargaritaArtifactCertificateContent: FC<ArtifactCertificateContentProps> = ({ artifact, certificate, obverseImage, defaultImage }) => {

    const styles = StyleSheet.create({
        certifiedOn: {
            position: "absolute",
            bottom: 102,
            left: 265,
            width: 500,
            fontFamily: "Patrick",
            fontWeight: "heavy"
        },
        defaultImage: {
            position: "absolute",
            top: 145,
            left: 50,
            width: 175,
        }
    });

    const data:ArtifactDetailsTableData[] = [
        {label: "Tag Number:", value: artifact.tag},
        {label: "Recovery Vessel:", value: artifact.vesselName},
        {label: "Recovery Date:", value: formatRecoveryDate(artifact.recoveryDate)},
        {label: "Description:", value: artifact.artifactDescriptionName},
        {label: "Comments:", value: artifact.certificateComments || ""}
    ];

    return (
        <>
        <ArtifactDetailsTable data={data} />
        
        <Image
            src={`data:image/jpg;base64,'${defaultImage?.data || obverseImage?.data || ArtifactImageNotAvailable}'`}
            style={styles.defaultImage} />


        <Text style={styles.certifiedOn}>This artifact was {certificate.isRecertification ? "re-certifed" : "certifed"} on {formatCertifiedOnDate(certificate.createdUtc)}.</Text>
      </>
    );
}


const Fleet1715CoinCertificateContent: FC<ArtifactCertificateContentProps> = ({ artifact, certificate, obverseImage, reverseImage }) => {

    const styles = StyleSheet.create({
        certifiedOn: {
            position: "absolute",
            bottom: 60,
            left: 265,
            width: 500,
            fontFamily: "Patrick",
            fontWeight: "heavy"
        }
    });

    const data:ArtifactDetailsTableData[] = [
        {label: "Coin Number:", value: artifact.coinNumber},
        {label: "Denomination:", value: artifact.denominationName},
        {label: "Reign:", value: artifact.reignName},
        {label: "Mint:", value: artifact.mintName},
        {label: "Assayer:", value: artifact.assayerName},
        {label: "Weight:", value: artifact.postWeightGm},
        {label: "Grade:", value: artifact.gradeName},
        {label: "Coin Date:", value: artifact.coinDate || "Not Visible"},
        {label: "Comments:", value: artifact.certificateComments || ""}
    ];

    return (
        <>
         
            <ArtifactDetailsTable data={data} />
        
            <Text style={certificateContentStyles.obverseImageLabel}>Obverse View:</Text>
            <Image
                src={`data:image/jpg;base64,'${obverseImage?.data || ArtifactImageNotAvailable}'`}
                style={certificateContentStyles.obverseImage} />

            <Text style={certificateContentStyles.reverseImageLabel}>Reverse View:</Text>
            <Image
                src={`data:image/jpg;base64,'${reverseImage?.data || ArtifactImageNotAvailable}'`}
                style={certificateContentStyles.reverseImage} />


            <Text style={styles.certifiedOn}>This artifact was {certificate.isRecertification ? "re-certifed" : "certifed"} on {formatCertifiedOnDate(certificate.createdUtc)}.</Text>
        </>
    );

}

const Fleet1715ArtifactCertificateContent: FC<ArtifactCertificateContentProps> = ({ artifact, certificate, obverseImage, defaultImage }) => {

    const styles = StyleSheet.create({
        certifiedOn: {
            position: "absolute",
            bottom: 45,
            left: 265,
            width: 500,
            fontFamily: "Patrick",
            fontWeight: "heavy"
        },
        defaultImage: {
            position: "absolute",
            top: 145,
            left: 50,
            width: 175,
        }
    });

    const data:ArtifactDetailsTableData[] = [
        {label: "Tag Number:", value: artifact.tag},
        {label: "Recovery Vessel:", value: artifact.vesselName},
        {label: "Recovery Date:", value: formatRecoveryDate(artifact.recoveryDate)},
        {label: "Description:", value: artifact.artifactDescriptionName},
        {label: "Comments:", value: artifact.certificateComments || ""}
    ];

    return (
        <>
        <ArtifactDetailsTable data={data} />
        
        <Image
            src={`data:image/jpg;base64,'${defaultImage?.data || obverseImage?.data || ArtifactImageNotAvailable}'`}
            style={styles.defaultImage} />


        <Text style={styles.certifiedOn}>This artifact was {certificate.isRecertification ? "re-certifed" : "certifed"} on {formatCertifiedOnDate(certificate.createdUtc)}.</Text>
      </>
    );
}