import { FormSelectItem } from "src/shared/components/form/form-select";



export enum ArtifactFileCategory {
    Image = 10,
    Document = 20
}

export enum ArtifactFileType {
    ImageDefault = 10,
    ImagePreConservation = 20,
    ImageObverse = 30,
    ImageReverse = 40,
    // ImageCard = 50, -- deprecated
    ImageAdditional = 60,
    Attachment = 70
}

export const ArtifactFileTypeDisplayNames: { [key in ArtifactFileType]: string } = {
    [ArtifactFileType.ImageDefault]: "Default",
    [ArtifactFileType.ImagePreConservation]: "Pre Conservation",
    [ArtifactFileType.ImageObverse]: "Obverse",
    [ArtifactFileType.ImageReverse]: "Reverse",
    // [ArtifactFileType.ImageCard]: "Card",
    [ArtifactFileType.ImageAdditional]: "Additional",
    [ArtifactFileType.Attachment]: "Attachment"
};

export const ArtifactImageFileTypes: FormSelectItem<number>[] = [
    {
        label: ArtifactFileTypeDisplayNames[ArtifactFileType.ImageDefault],
        value: ArtifactFileType.ImageDefault
    },
    {
        label: ArtifactFileTypeDisplayNames[ArtifactFileType.ImageObverse],
        value: ArtifactFileType.ImageObverse
    },
    {
        label: ArtifactFileTypeDisplayNames[ArtifactFileType.ImageReverse],
        value: ArtifactFileType.ImageReverse
    },
    {
        label: ArtifactFileTypeDisplayNames[ArtifactFileType.ImagePreConservation],
        value: ArtifactFileType.ImagePreConservation
    },
    {
        label: ArtifactFileTypeDisplayNames[ArtifactFileType.ImageAdditional],
        value: ArtifactFileType.ImageAdditional
    }
];


export interface ArtifactFileModel {
    id: number;
    artifactId?: number;
    type: ArtifactFileType;
    category: ArtifactFileCategory;
    blobPath: string;
    blobName: string;
    blobExtension: string;
    title?: string;
    sizeBytes: number;
    label?: string;
    width?: number;
    height?: number;
    resolutionX?: number;
    resolutionY?: number;
    hasThumbnail?: boolean;
    createdByUserId?: number;
    createdUtc: Date;
}

export interface ArtifactImage {
    data: string;
    model?: ArtifactFileModel
    label: string;
  }

  export const ArtifactLoadingImage = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";