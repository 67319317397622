import type { RouteObject } from 'react-router';
import { lazy, Suspense } from 'react';
import { GuestGuard } from 'src/shared/guards/guest-guard';
import { LoginLayout } from 'src/shared/layouts/root/login-layout';
import { AppLayout } from 'src/shared/layouts/root/app-layout/app-layout';
import Error401Page from 'src/pages/error/401';
import Error403Page from 'src/pages/error/403';
import Error404Page from 'src/pages/error/404';
import Error500Page from 'src/pages/error/500';
import { Outlet } from 'react-router-dom';
import ForgotPasswordPage from 'src/pages/password/forgot';
import LoginPage from 'src/pages/login/login';
import ArtifactLocationListPage from 'src/pages/management/artifact-location/list';
import ArtifactStatusListPage from 'src/pages/management/artifact-status/list';
import AssayerListPage from 'src/pages/management/assayer/list';
import CuratorDispositionListPage from 'src/pages/management/curator-disposition/list';
import DenominationListPage from 'src/pages/management/denomination/list';
import MintListPage from 'src/pages/management/mint/list';
import ProtocolListPage from 'src/pages/management/protocol/list';
import ReignListPage from 'src/pages/management/reign/list';
import ArtifactTypeListPage from 'src/pages/management/artifact-type/list';
import OwnerListPage from 'src/pages/management/owner/list';
import ConservatorListPage from 'src/pages/management/conservator/list';
import GradeListPage from 'src/pages/management/grade/list';
import ArtifactClassListPage from 'src/pages/management/artifact-class/list';
import DispositionListPage from 'src/pages/management/dispositions/list';
import PearlGradeListPage from 'src/pages/management/pearl-grade/list';
import ChemicalStabilizationListPage from 'src/pages/management/chemical-stabilization/list';
import ArtifactGroupListPage from 'src/pages/management/artifact-group/list';
import ChlorideRemovalListPage from 'src/pages/management/chloride-removal/list';
import DivisionTypeListPage from 'src/pages/management/division-type/list';
import ContractTypeListPage from 'src/pages/management/contract-type/list';
import InvestorTypeListPage from 'src/pages/management/investor-type/list';
import InvestorRelationsContactListPage from 'src/pages/management/investor-relations-contact/list';
import { paths } from './paths';
import PaymentMethodTypeListPage from 'src/pages/management/payment-method-type/list';
import PhoneNumberTypeListPage from 'src/pages/management/phone-number-type/list';
import ArtifactSearchPage from 'src/pages/artifact/artifact-search';
import DashboardPage from 'src/pages/dashboard/dashboard';
import UserUpdatePage from 'src/pages/admin/user/update';
import UserCreatePage from 'src/pages/admin/user/create';
import UserListPage from 'src/pages/admin/user/list';
import IpAddressListPage from 'src/pages/admin/ip-address/list';
import IpAddressCreatePage from 'src/pages/admin/ip-address/create';
import OperationListPage from 'src/pages/management/operation/list';
import VesselListPage from 'src/pages/management/vessel/list';
import WreckSiteListPage from 'src/pages/management/wreck-site/list';
import AssociateTypeListPage from 'src/pages/management/associate-type/list';
import InvestorLevelListPage from 'src/pages/management/investor-level/list';
import ConservationResultListPage from 'src/pages/management/conservation-result/list';
import EmailAddressTypeListPage from 'src/pages/management/email-address-type/list';
import AddressTypeListPage from 'src/pages/management/address-type/list';
import ChlorideReleaseTestListPage from 'src/pages/management/chloride-release-test/list';
import ConcretionRemovalMethodListPage from 'src/pages/management/concretion-removal-method/list';
import LeadSourceListPage from 'src/pages/management/lead-source/list';
import BlueHoleContractTypeListPage from 'src/pages/management/blue-hole-contract-types/list';
import TradeTypeListPage from 'src/pages/management/trade-type/list';
import CoinDateRemarkListPage from 'src/pages/management/coin-date-remark/list';
import ArtifactDescriptionListPage from 'src/pages/management/artifact-description/list';
import ArtifactDescriptionManagePage from 'src/pages/management/artifact-description/manage';
import GalleonSectionListPage from 'src/pages/management/galleon-section/list';
import GalleonSectionManagePage from 'src/pages/management/galleon-section/manage';
import GalleonActivityAreaListPage from 'src/pages/management/galleon-activity-area/list';
import GalleonActivityAreaManagePage from 'src/pages/management/galleon-activity-area/manage';
import ArtifactManagePage from 'src/pages/artifact/artifact-manage';
import ArtifactImagePage from 'src/pages/artifact/artifact-images';
import BuildingListPage from 'src/pages/management/building/list';
import BuildingManagePage from 'src/pages/management/building/manage';
import SafeListPage from 'src/pages/management/safe/list';
import SafeManagePage from 'src/pages/management/safe/manage';
import ShelfListPage from 'src/pages/management/shelf/list';
import ShelfManagePage from 'src/pages/management/shelf/manage';
import BinListPage from 'src/pages/management/bin/list';
import BinManagePage from 'src/pages/management/bin/manage';
import ArtifactTransferPage from 'src/pages/artifact/artifact-transfer';
import CoinDetailsReportPage from 'src/pages/report/coin-details-report';
const ResetPasswordPage = lazy(() => import('src/pages/password/reset'));
const RegisterPage = lazy(() => import('src/pages/register/register'));


export const routes: RouteObject[] = [
	{
		path: '',
		element: (
			<GuestGuard>
				<LoginLayout>
					<Outlet />
				</LoginLayout>
			</GuestGuard>
		),
		children: [
			{
				path: 'login',
				element: <LoginPage />
			},
			{
				path: 'password',
				children: [
					{
						path: 'forgot',
						element: <ForgotPasswordPage />
					},
					{
						path: `reset/:token`,
						element: <ResetPasswordPage />
					},
				]
			},
			
			{
				path: 'register/:token',
				element: <RegisterPage />
			}
		]
	},
	{
		path: '',
		element: (
			<AppLayout>
				<Suspense>
					<Outlet />
				</Suspense>
			</AppLayout>
		),
		children: [
			{
				index: true,
				element: <DashboardPage />
			},
			{
				path: paths.artifacts.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ArtifactSearchPage />
					},
					{
						path: paths.artifacts.transfer.split('/').pop(),
						element: <ArtifactTransferPage />
					},
					{
						path: paths.artifacts.update.replace(paths.artifacts.list + '/',''),
						element: <ArtifactManagePage/>
					},
					{
						path: paths.artifacts.create.split('/').pop(),
						element: <ArtifactManagePage />
					},
					{
						path: paths.artifacts.images.replace(paths.artifacts.list + '/',''),
						element: <ArtifactImagePage />
					}
				]
			},
			{
				path: 'reports',
				children: [
					{
						path: paths.reports.coinDetails,
						element: <CoinDetailsReportPage />
					}
				]
			}
		]
	},
	{
		path: 'admin',
		element: (
			<AppLayout>
				<Suspense>
					<Outlet />
				</Suspense>
			</AppLayout>
		),
		children: [
			{
				path: paths.admin.users.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <UserListPage />
					},
					{
						path: paths.admin.users.update.replace(paths.admin.users.list + '/',''),
						element: <UserUpdatePage/>
					},
					{
						path: paths.admin.users.create.split('/').pop(),
						element: <UserCreatePage />
					}
				]
			},
			{
				path: paths.admin.ipAddresses.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <IpAddressListPage />
					},
					{
						path: paths.admin.ipAddresses.create.split('/').pop(),
						element: <IpAddressCreatePage />
					}
				]
			}]
		},
		{
			path: 'management',
			element: (
				<AppLayout>
					<Suspense>
						<Outlet />
					</Suspense>
				</AppLayout>
			),
			children: [
			{
				path: paths.admin.operations.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <OperationListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.vessels.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <VesselListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.wreckSites.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <WreckSiteListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},		
			{
				path: paths.admin.artifactLocations.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ArtifactLocationListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.artifactStatuses.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ArtifactStatusListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.assayers.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <AssayerListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.curatorDispositions.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <CuratorDispositionListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.denominations.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <DenominationListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.mints.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <MintListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.protocols.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ProtocolListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.reigns.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ReignListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},		
			{
				path: paths.admin.artifactTypes.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ArtifactTypeListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},	
			{
				path: paths.admin.owners.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <OwnerListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.conservators.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ConservatorListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.grades.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <GradeListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.artifactClasses.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ArtifactClassListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.dispositions.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <DispositionListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.pearlGrades.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <PearlGradeListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.chemicalStabilizations.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ChemicalStabilizationListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.artifactGroups.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ArtifactGroupListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.chlorideRemovals.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ChlorideRemovalListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.divisionTypes.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <DivisionTypeListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.contractTypes.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ContractTypeListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.investorTypes.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <InvestorTypeListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.investorRelationsContacts.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <InvestorRelationsContactListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.paymentMethodTypes.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <PaymentMethodTypeListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.phoneNumberTypes.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <PhoneNumberTypeListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.associateTypes.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <AssociateTypeListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.investorLevels.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <InvestorLevelListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.conservationResults.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ConservationResultListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.emailAddressTypes.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <EmailAddressTypeListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.addressTypes.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <AddressTypeListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.chlorideReleaseTests.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ChlorideReleaseTestListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.concretionRemovalMethods.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ConcretionRemovalMethodListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.leadSources.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <LeadSourceListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.blueHoleContractTypes.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <BlueHoleContractTypeListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.tradeTypes.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <TradeTypeListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.coinDateRemarks.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <CoinDateRemarkListPage />
					},
					// {
					// 	path: 'create',
					// 	element: <Element />
					// }
				]
			},
			{
				path: paths.admin.artifactDescriptions.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ArtifactDescriptionListPage />
					},
					{
						path: paths.admin.artifactDescriptions.update.replace(paths.admin.artifactDescriptions.list + '/',''),
						element: <ArtifactDescriptionManagePage/>
					},
					{
						path: paths.admin.artifactDescriptions.create.split('/').pop(),
						element: <ArtifactDescriptionManagePage />
					}
				]
			},
			{
				path: paths.admin.galleonSections.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <GalleonSectionListPage />
					},
					{
						path: paths.admin.galleonSections.update.replace(paths.admin.galleonSections.list + '/',''),
						element: <GalleonSectionManagePage/>
					},
					{
						path: paths.admin.galleonSections.create.split('/').pop(),
						element: <GalleonSectionManagePage />
					}
				]
			},
			{
				path: paths.admin.galleonActivityAreas.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <GalleonActivityAreaListPage />
					},
					{
						path: paths.admin.galleonActivityAreas.update.replace(paths.admin.galleonActivityAreas.list + '/',''),
						element: <GalleonActivityAreaManagePage/>
					},
					{
						path: paths.admin.galleonActivityAreas.create.split('/').pop(),
						element: <GalleonActivityAreaManagePage />
					}
				]
			},
			{
				path: paths.admin.buildings.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <BuildingListPage />
					},
					{
						path: paths.admin.buildings.update.replace(paths.admin.buildings.list + '/',''),
						element: <BuildingManagePage/>
					},
					{
						path: paths.admin.buildings.create.split('/').pop(),
						element: <BuildingManagePage />
					}
				]
			},
			{
				path: paths.admin.safes.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <SafeListPage />
					},
					{
						path: paths.admin.safes.update.replace(paths.admin.safes.list + '/',''),
						element: <SafeManagePage/>
					},
					{
						path: paths.admin.safes.create.split('/').pop(),
						element: <SafeManagePage />
					}
				]
			},
			{
				path: paths.admin.shelfs.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <ShelfListPage />
					},
					{
						path: paths.admin.shelfs.update.replace(paths.admin.shelfs.list + '/',''),
						element: <ShelfManagePage/>
					},
					{
						path: paths.admin.shelfs.create.split('/').pop(),
						element: <ShelfManagePage />
					}
				]
			},
			{
				path: paths.admin.bins.list.split('/').pop(),
				children: [
					{
						index: true,
						element: <BinListPage />
					},
					{
						path: paths.admin.bins.update.replace(paths.admin.bins.list + '/',''),
						element: <BinManagePage/>
					},
					{
						path: paths.admin.bins.create.split('/').pop(),
						element: <BinManagePage />
					}
				]
			},
		]},
			{
				path: '401',
				element: <Error401Page />
			},
			{
				path: '403',
				element: <Error403Page />
			},
			{
				path: '404',
				element: <Error404Page />
			},
			{
				path: '500',
				element: <Error500Page />
			},
			{
				path: '*',
				element: <Error404Page />
			}
		];
