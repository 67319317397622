
import { GridColDef } from '@mui/x-data-grid';
import { UIDataGrid } from 'src/shared/components/ui/ui-data-grid';
import { FC, useEffect, useRef, useState } from 'react';
import { useMount } from 'src/shared/hooks/use-mount';
import { Api } from 'src/api/api';
import { ApiQuery } from 'src/api/api-query';
import UIMessage from 'src/shared/components/ui/ui-message';
import { paths } from 'src/routes/paths';
import { nameof } from 'src/shared/utils/nameof';
import { textColumn, idColumn, dateColumn, deleteActionColumn } from 'src/shared/components/grid-columns';
import { useAuth } from 'src/shared/hooks/use-auth';
import { useLocation, useNavigate } from 'react-router';
import { Permission } from 'src/models/permission';
import { ArtifactRow, ArtifactTagSearchMode, artifactTagSearchModes } from 'src/models/artifact';
import { GetArtifactsQuery, GetArtifactsQueryResult } from 'src/api/queries/artifact/get-artifacts';

import {ArtifactSearchPanel, ArtifactSearchPanelDatasources, ArtifactSearchPanelFields } from './components/artifact-search-panel';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, SvgIcon, Typography } from '@mui/material';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ArtifactDrawer } from './components/artifact-drawer';
import { useDialog } from 'src/shared/hooks/use-dialog';
import { RouterLink } from 'src/shared/components/router-link';
import { GetWreckSitesQuery } from 'src/api/queries/wreck-site/get-wreck-sites';
import { GetVesselsQuery } from 'src/api/queries/vessel/get-vessels';
import { SearchResultsContentLayout } from '../../shared/layouts/content/search-results-content-layout';
import { getLookupFormSelectItems, LookupFormSelectItemLabel } from 'src/shared/utils/get-lookup-select-items';
import { ArtifactTransferModal, ArtifactTransferModalDatasources, ArtifactTransferModalFields, ArtifactTransferResult } from './components/artifact-transfer-modal';
import { VesselRow } from 'src/models/vessel';
import { LookupRow } from 'src/models/lookup';
import { GetLookupRowsQuery } from 'src/api/queries/lookup/get-lookup-rows';
import { GetBuildingsQuery } from 'src/api/queries/building/get-buildings';
import { BuildingRow } from 'src/models/building';
import { GetSafesQuery } from 'src/api/queries/safe/get-safes';
import { SafeRow } from 'src/models/safe';
import { GetShelfsQuery } from 'src/api/queries/shelf/get-shelfs';
import { ShelfRow } from 'src/models/shelf';
import { GetBinsQuery } from 'src/api/queries/bin/get-bins';
import { BinRow } from 'src/models/bin';
import { WreckSiteRow } from 'src/models/wreck-site';
import { CreateArtifactTransferCommand } from 'src/api/commands/artifact-transfer';
import { ApiCommand, CreateCommandResult } from 'src/api/api-command';
import { ArtifactFileType, ArtifactImageFileTypes } from 'src/models/artifact-file';



const ArtifactTransferPage: FC = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const { hash } = useLocation();
    const loadingSize = 10;
    const loadingRows: ArtifactRow[] = ((rows: number) => {
        return [...Array(rows)].map((row, index) => {
            return {
                id: Number.MAX_SAFE_INTEGER - index,
            }
        });
    })(loadingSize);

    const artifactsColumns: GridColDef[] = [
        idColumn(loadingSize, nameof<ArtifactRow>("id")),
        textColumn(nameof<ArtifactRow>("tag"), "Tag"),
        textColumn(nameof<ArtifactRow>("artifactDescriptionName"), "Description"),
        textColumn(nameof<ArtifactRow>("wreckSiteName"), "Wreck Site"),
        dateColumn(nameof<ArtifactRow>("recoveryDate"), "Recovered"),
        textColumn(nameof<ArtifactRow>("points"), "Points", .10)
    ];

    const artifactsBatchColumns = [
        ...artifactsColumns,
        deleteActionColumn(true, async (rowId) => { removeArtifactFromTransferBatch(rowId); }, .20)
    ];

    const [artifacts, setArtifacts] = useState<ArtifactRow[]>(loadingRows);
    const [artifactsBatch, setArtifactsBatch] = useState<ArtifactRow[]>([])
    const [artifactIdsSelected, setArtifactIdsSelected] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [errors, setErrors] = useState<string[]>([]);
    const rootRef = useRef<HTMLDivElement | null>(null);
    const [searchPanelOpen, setSearchPanelOpen] = useState<boolean>(true);
    const [selectedArtifactId, setSelectedArtifactId] = useState<number>();
    const [artifactSearchPanelDatasources, setArtifactSearchPanelDatasources] = useState<ArtifactSearchPanelDatasources>();
    const [ArtifactTransferModalDatasources, setArtifactTransferModalDatasources] = useState<ArtifactTransferModalDatasources>();

    const artifactDialog = useDialog<number>();
    const artifactTransferBatchDialog = useDialog();
    const handleArtifactSelect = (artifactId: string): void => {
        navigate('#' + artifactId);
    };

    const handleArtifactDrawerClose = (): void => {
        navigate("#");
    }

    const handleSearchPanelSubmit = async (fields: ArtifactSearchPanelFields) => {
        setArtifacts(loadingRows);
        setLoading(true);
        const {artifactFileTypeCodes, ...request} = fields;
        const result = await Api.executeQuery<GetArtifactsQuery, GetArtifactsQueryResult>(
            ApiQuery.GetArtifacts, {
            ...request,
            hasImageDefault: artifactFileTypeCodes?.includes(ArtifactFileType.ImageDefault) || undefined,
            HasImageObverse: artifactFileTypeCodes?.includes(ArtifactFileType.ImageObverse) || undefined,
            hasImageReverse: artifactFileTypeCodes?.includes(ArtifactFileType.ImageReverse) || undefined,
            hasImagePreCon: artifactFileTypeCodes?.includes(ArtifactFileType.ImagePreConservation) || undefined,
            hasImageAdditional: artifactFileTypeCodes?.includes(ArtifactFileType.ImageAdditional) || undefined
        });
        setArtifacts(result.rows);

        setLoading(false);
    };

    const handleSearchPanelReset = async (fields: ArtifactSearchPanelFields) => {
        setLoading(true);

        const result = await Api.executeQuery<GetArtifactsQuery, GetArtifactsQueryResult>(
            ApiQuery.GetArtifacts, {
            tagSearchMode: ArtifactTagSearchMode.ExactMatch,
        });
        setArtifacts(result.rows);

        setLoading(false);
    };

    
    const handleTransferModalSubmit = async (fields: ArtifactTransferModalFields) : Promise<ArtifactTransferResult> => {
        setLoading(true);
  
        try {
            const command: CreateArtifactTransferCommand = {
                artifactIds: fields.artifactIds,
                transferType: fields.transferType,
                artifactLocationId: fields.artifactLocationId,
                curatorDispositionId: fields.curatorDispositionId,
                binId: fields.binId
            };
    
            const commandResult = await Api.executeCommand<CreateArtifactTransferCommand, CreateCommandResult>(
                ApiCommand.CreateArtifactTransfer,
                command);
            
            setArtifactsBatch([]);
            setLoading(false);
       
            return { success: true, result: {batchId: commandResult.id}};
        } catch (err) {
            setLoading(false);
            return { success: false, errors: (err?.response?.data?.errors || [err.response.statusText])}
        }


    };

    const artifactIsInTransferBatch = (id: number) => {
        return artifactsBatch.findIndex(f => f.id === id) > -1;
    }

    const removeArtifactFromTransferBatch = (id: number) => {
        setArtifactsBatch(values => values.filter(v => v.id !== id));
    }


    useEffect(() => {
        const artifactId = + ((hash || "").replace("#", "").trim());
        if (artifactId > 0) {
            setSelectedArtifactId(artifactId)
            artifactDialog.handleOpen(artifactId);
        } else {
            setSelectedArtifactId(undefined)
            artifactDialog.handleClose();
        }

        // Listen for hash change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash])


    useMount(() => {

        if (!auth.authState.principal?.hasPermission(Permission.ArtifactsRead)) {
            navigate(paths.error403);
            return;
        }

        const loadData = async () => {

            try {
                const artifactDescriptions = await getLookupFormSelectItems(ApiQuery.GetArtifactDescriptions, LookupFormSelectItemLabel.All);
                const artifactTypes = await getLookupFormSelectItems(ApiQuery.GetArtifactTypes, LookupFormSelectItemLabel.All);
                const operations = await getLookupFormSelectItems(ApiQuery.GetOperations, LookupFormSelectItemLabel.All);
                const wreckSites = await Api.executeRowsQuery<GetWreckSitesQuery, WreckSiteRow>(ApiQuery.GetWreckSites, {});
                const vessels = await Api.executeRowsQuery<GetVesselsQuery, VesselRow>(ApiQuery.GetVessels, {});
                const artifactFileTypes = [{value: -1, label: "Any"}, ...ArtifactImageFileTypes]
                
                setArtifactSearchPanelDatasources({
                        artifactDescriptions,
                        artifactTypes,
                        operations,
                        wreckSites,
                        vessels,
                        artifactTagSearchModes,
                        artifactFileTypes
                });

                const artifactLocations = await Api.executeRowsQuery<GetLookupRowsQuery, LookupRow>(ApiQuery.GetArtifactLocations);
                const curatorDispositions = await Api.executeRowsQuery<GetLookupRowsQuery, LookupRow>(ApiQuery.GetCuratorDispositions);
                const buildings = await Api.executeRowsQuery<GetBuildingsQuery, BuildingRow>(ApiQuery.GetBuildings);
                const safes = await Api.executeRowsQuery<GetSafesQuery, SafeRow>(ApiQuery.GetSafes);
                const shelfs = await Api.executeRowsQuery<GetShelfsQuery, ShelfRow>(ApiQuery.GetShelfs);
                const bins = await Api.executeRowsQuery<GetBinsQuery, BinRow>(ApiQuery.GetBins);

                setArtifactTransferModalDatasources({
                    artifactLocations,
                    curatorDispositions,
                    buildings,
                    safes,
                    shelfs,
                    bins
                })

                const result = await Api.executeQuery<GetArtifactsQuery, GetArtifactsQueryResult>(
                    ApiQuery.GetArtifacts, {
                    tagSearchMode: ArtifactTagSearchMode.ExactMatch,
                });
                setArtifacts(result.rows);

                setLoading(false);
            } catch (err) {
                setErrors(err.response.data.errors || [err.response.statusText])
                setLoading(false);
            }
        }

        loadData();
    });

    return (
        <>
            <Box
                component="main"
                sx={{
                    display: 'flex',
                    flex: '1 1 auto',
                    overflow: 'hidden',
                    position: 'relative',

                }}
            >
                <Box
                    ref={rootRef}
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        left: 0,
                        bottom: 0
                    }}
                >

                    <ArtifactSearchPanel
                        datasources={artifactSearchPanelDatasources}
                        container={rootRef.current}
                        open={searchPanelOpen}
                        onOpenChanged={setSearchPanelOpen}
                        onSubmit={handleSearchPanelSubmit}
                        onReset={handleSearchPanelReset}
                    />

                    <SearchResultsContentLayout open={searchPanelOpen}>
                        <Stack spacing={4}>
                            <Stack
                                alignItems="flex-start"
                                direction="row"
                                justifyContent="space-between"
                                spacing={3}
                            >
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={2}
                                >

                                    <Typography variant="h4">
                                        Transfer Artifacts
                                    </Typography>
                                </Stack>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >


                                </Stack>

                            </Stack>
                            <Stack>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography variant="h5">Transfer Batch ({artifactsBatch.length})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Stack
                                            alignItems="flex-start"
                                            direction="row"
                                            justifyContent="space-between"
                                            spacing={3}
                                            mb={2}
                                        >
                                            <Stack
                                                alignItems="center"
                                                direction="row"
                                                spacing={2}
                                            >
                                                &nbsp;
                                            </Stack>
                                            <Stack
                                                alignItems="center"
                                                direction="row"
                                                spacing={1}
                                            >

                                                <Button
                                                    startIcon={(
                                                        <SvgIcon>
                                                            <MoveDownIcon />
                                                        </SvgIcon>
                                                    )}
                                                    variant="contained"
                                                    size='small'
                                                    disabled={artifactsBatch.length === 0}
                                                    onClick={() => {
                                                        artifactTransferBatchDialog.handleOpen();
                                                    }}
                                                >
                                                    Transfer
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    component={RouterLink}
                                                    disabled={artifactsBatch.length === 0}
                                                    href={paths.artifacts.transfer}
                                                    onClick={() => {
                                                        setArtifactsBatch([]);
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Stack>

                                        </Stack>

                                        <UIDataGrid
                                            loading={false}
                                            hideFooterPagination={false}
                                            rows={artifactsBatch}
                                            columns={artifactsBatchColumns}
                                            disableColumnSelector={true}
                                            onRowClick={(e) => handleArtifactSelect(e.row['id'])}
                                            slotProps={{
                                                toolbar: {
                                                    showQuickFilter: false,
                                                    csvOptions: { disableToolbarButton: true },
                                                    printOptions: { disableToolbarButton: true },
                                                },
                                            }}
                                            noRowsMessage="No transfers added to batch."
                                            rowPointer={true}
                                        />

                                    </AccordionDetails>
                                </Accordion>
                            </Stack>
                            <UIMessage
                                type="error"
                                messages={errors}
                                onClose={() => setErrors([])} />
                            <Stack>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography variant="h5">
                                            Search Results {!loading && (
                                                "(" + artifacts.length + ")"
                                                )}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Stack
                                            alignItems="flex-start"
                                            direction="row"
                                            justifyContent="space-between"
                                            spacing={3}
                                            mb={2}
                                        >
                                            <Stack
                                                alignItems="center"
                                                direction="row"
                                                spacing={2}
                                            >
                                                &nbsp;
                                            </Stack>
                                            <Stack
                                                alignItems="center"
                                                direction="row"
                                                spacing={1}
                                            >

                                                <Button
                                                    startIcon={(
                                                        <SvgIcon>
                                                            <PlusIcon />
                                                        </SvgIcon>
                                                    )}
                                                    variant="contained"
                                                    disabled={artifactIdsSelected.length === 0}
                                                    size='small'
                                                    onClick={() => {

                                                        const selectedArtifacts = artifacts.filter((row) =>
                                                            artifactIdsSelected.indexOf(row.id) > -1
                                                        );

                                                        setArtifactsBatch(data => {
                                                            const rows = data.concat(selectedArtifacts);
                                                            return rows;
                                                        });

                                                        setArtifactIdsSelected([]);
                                                    }}

                                                >
                                                    Add {artifactIdsSelected.length} to Transfer Batch
                                                </Button>
                                            </Stack>

                                        </Stack>
                                        <UIDataGrid
                                            loading={loading}
                                            hideFooterPagination={loading}
                                            rows={artifacts}
                                            columns={artifactsColumns}
                                            disableColumnSelector={true}
                                            onRowClick={(e) => handleArtifactSelect(e.row['id'])}
                                            slotProps={{
                                                toolbar: {
                                                    showQuickFilter: false,
                                                    csvOptions: { disableToolbarButton: true },
                                                    printOptions: { disableToolbarButton: true },
                                                },
                                            }}
                                            rowPointer={true}
                                            isRowSelectable={(params) => artifactIsInTransferBatch(+params.id) ? false : true}
                                            getRowClassName={(params) => artifactIsInTransferBatch(+params.id) ? "uidatagrid-row-disabled" : ""}
                                            checkboxSelection={true}
                                            rowSelectionModel={artifactIdsSelected}
                                            onRowSelectionModelChange={(ids) => {
                                                setArtifactIdsSelected(ids);
                                            }}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Stack>

                        </Stack>

                    </SearchResultsContentLayout>

                    <ArtifactDrawer
                        container={rootRef.current}
                        onClose={() => handleArtifactDrawerClose()}
                        open={artifactDialog.open}
                        artifactId={selectedArtifactId}
                    />
                </Box>

            </Box>
            {ArtifactTransferModalDatasources && (
                <ArtifactTransferModal
                    datasources={ArtifactTransferModalDatasources}
                    artifacts={artifactsBatch}
                    onClose={artifactTransferBatchDialog.handleClose}
                    open={artifactTransferBatchDialog.open}
                    onSubmit={handleTransferModalSubmit}
                />
            )}
         
        </>



    );
};

export default ArtifactTransferPage;

